import Title from "antd/es/typography/Title";
import Text from "antd/es/typography/Text";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Card, Col, Divider, Pagination, Row, Space } from "antd";
import Navigation from "../../components/layout/Navigation";
import TextArea from "antd/es/input/TextArea";

const DetailForum = () => {
  const [forum, setForum] = useState([""]);
  const [comment, setComment] = useState([""]);
  const [totalData, setTotalData] = useState(0);
  const [current, setCurrent] = useState(1);
  const { id } = useParams();
  const token = localStorage.getItem("token");

  const getData = (pageSize, pageIndex) => {
    axios
      .get(
        `${
          process.env.REACT_APP_URL
        }cultureapp/forum/detail_forum_comments/${id}/${pageSize ?? 10}/${
          pageIndex ?? 1
        }`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        setComment(res.data.data);
        setTotalData(res.data.totaldata);
      })
      .catch((err) => {
        alert(err);
      });
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL}cultureapp/forum/detail_forum/${id}`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        setForum(res.data);
      })
      .catch((err) => {
        alert(err);
      });
    getData();
  }, [token, id]);

  return (
    <div className="">
      <Navigation
        link="/categories"
        title="Categories"
        // link2={`/detail-categories/${id}`}
        title2="Category Detail Forum"
        title3="Detail Forum"
      />
      <Space direction="vertical" size="small">
        <Title level={2} className="fw-bold">
          {forum.title}
        </Title>
        <div className="mt-2">
          <img
            src="https://cdn-icons-png.flaticon.com/512/3135/3135823.png"
            width={30}
          ></img>
          <Space direction="vertical" className="ms-2">
            <Text className="fw-bold">{forum.author}</Text>
            <Text>{forum.body}</Text>
            <Text className="text-secondary">{forum.timecreated}</Text>
          </Space>
        </div>
        <Row className="mt-3">
          <Col span={2}>
            <img
              src="https://static.vecteezy.com/system/resources/previews/005/544/718/original/profile-icon-design-free-vector.jpg"
              width={30}
            ></img>
          </Col>
          <Col span={20}>
            <TextArea placeholder="add comment" className=""/>
            <Button type="primary" style={{marginTop:"10px"}}>
              Submit
            </Button>
          </Col>
        </Row>
        <p className="mt-3">
          {totalData} {totalData === 1 ? "Comment:" : "Comments:"}
        </p>
        {comment.map((item) => (
          <Space direction="vertical">
            <Space size="small">
              <img
                src="https://static.vecteezy.com/system/resources/previews/005/544/718/original/profile-icon-design-free-vector.jpg"
                width={30}
              ></img>
              <Text className="fw-bold">{item.author}</Text>
              <Text>{item.comment}</Text>
            </Space>
            <Text className="text-secondary">{item.timecreated}</Text>
            <Divider />
          </Space>
        ))}
        <Pagination
          current={current}
          total={totalData}
          onChange={(page, size) => {
            getData(size, page);
            setCurrent(page);
          }}
        />
      </Space>
    </div>
  );
};

export default DetailForum;
