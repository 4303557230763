import { Button, Form, Input, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import Title from "antd/es/typography/Title";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navigation from "../../components/layout/Navigation";

const AddForum = () => {
  const navigate = useNavigate();
  const [pic, setPic] = useState("");
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const [form, setForm] = useState({
    title: "",
    body: "",
  });

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handlePic = (e) => {
    e.preventDefault();
    setPic(e.target.files[0]);
  };

  const handleSubmit = () => {
    const PPData = new FormData();
    PPData.append("image", pic);
    PPData.append("title", form.title);
    PPData.append("body", form.body);
    PPData.append("idcategory", id);
    axios
      .post(`${process.env.REACT_APP_URL}cultureapp/forum/add`, PPData, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        navigate(`/detail-categories/${id}`);
        message.success("You have successfully submitted!");
      })
      .catch((err) => {
        message.error("Error! Try again.");
      });
  };

  return (
    <>
      <Navigation
        link="/categories"
        title="Categories"
        link2={`/detail-categories/${id}`}
        title2="Category Detail"
        title3="Add Forum"
      />
      <Title>Add Forum</Title>
      <Form layout="vertical" className="row-col">
        <div>
          {pic ? <img alt="" width={150} src={URL.createObjectURL(pic)} /> : ""}
        </div>
        <Form.Item label="Set Image(optional):" name="pic" className="mt-3">
          <Input type="file" name="pic" className="w-95" onChange={handlePic} />
        </Form.Item>
        <Form.Item label="Title:" name="title">
          <Input
            type="text"
            name="title"
            className="w-95"
            placeholder="title"
            value={form.title}
            onChange={handleChange}
          />
        </Form.Item>
        <Form.Item label="Body:" name="body">
          <TextArea
            type="text"
            name="body"
            className="w-95"
            placeholder="body"
            value={form.body}
            onChange={handleChange}
          />
        </Form.Item>
      </Form>
      <Button type="primary w-95 btn-submit" onClick={handleSubmit}>
        Submit
      </Button>
    </>
  );
};

export default AddForum;
