import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Pagination,
  Space,
  Switch,
  Table,
} from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import { ModalDelete } from "../../components/layout/ModalDelete";
import { SendOutlined, EditOutlined } from "@ant-design/icons";
import { AddButton } from "../../components/layout/AddButton";
import { useNavigate } from "react-router-dom";
import Navigation from "../../components/layout/Navigation";
import axios from "axios";
import Search from "antd/es/input/Search";

const Notification = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [dataNotification, setDataNotification] = useState([""]);
  const [checked, setChecked] = useState(true);
  const [time, setTime] = useState("");
  const [id, setId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [totalData, setTotalData] = useState(0);
  const [current, setCurrent] = useState(1);
  const token = localStorage.getItem("token");

  const getData = (pageSize, pageIndex, searchIndex) => {
    axios
      .get(
        `${process.env.REACT_APP_URL}cultureapp/notification/list/${
          pageSize ?? 10
        }/${pageIndex ?? 1}`,
        {
          headers: { Authorization: "Bearer " + token },
          params: { search: searchIndex },
        }
      )
      .then((res) => {
        setDataNotification(res.data.data);
        setTotalData(res.data.totaldata);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const onChange = (dateString) => {
    setTime(Math.floor(new Date(dateString).getTime() / 1000));
  };

  const hideModal = () => {
    setOpen(false);
  };

  const data = dataNotification.map((row) => ({
    id: row.id,
    no: row.number,
    title: row.title,
    body: row.body,
    timesend: row.timesend,
  }));

  const handleAdd = () => {
    if (checked === true) {
      axios
        .post(
          `${process.env.REACT_APP_URL}cultureapp/notification/send_notification`,
          { id_notification: id, now: checked, date: 0 },
          {
            headers: { Authorization: "Bearer " + token },
          }
        )
        .then((res) => {
          window.location.reload(false);
          message.success("You have successfully sent!");
        })
        .catch((err) => {
          message.error("Error! Try again.");
        });
    } else if (checked === false) {
      axios
        .post(
          `${process.env.REACT_APP_URL}cultureapp/notification/send_notification`,
          { id_notification: id, now: checked, date: time },
          {
            headers: { Authorization: "Bearer " + token },
          }
        )
        .then((res) => {
          window.location.reload(false);
          message.success("You have successfully sent!");
        })
        .catch((err) => {
          message.error("Error! Try again.");
        });
    }
  };

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "5%",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Description",
      dataIndex: "body",
      key: "body",
    },
    {
      title: "Date",
      dataIndex: "timesend",
      key: "timesend",
    },
    {
      title: "Action",
      key: "action",
      width: "15%",
      render: (_, record) => (
        <Space size="middle">
          <Button
            className="outline-0"
            onClick={() => navigate(`/notification/update-notification/${record.id}`)}
          >
            <EditOutlined />
          </Button>
          <ModalDelete url="notification" id={record.id}></ModalDelete>
          {_.timesend ? (
            ""
          ) : (
            <Button
              className="outline-0"
              onClick={() => {
                setOpen(true);
                setId(record.id);
              }}
            >
              <SendOutlined />
            </Button>
          )}
        </Space>
      ),
    },
  ];
  return (
    <>
      <Navigation link="/notification" title="Notification" />
      <Title level={2}>Notification</Title>
      <div className="title">
        <Search
          placeholder="Search Name"
          onSearch={(value) => {
            getData(10, 1, value);
          }}
          style={{ width: "60%" }}
          allowClear
        />
        <AddButton
          children="notification"
          click={() => navigate("/notification/add-notification")}
        />
      </div>
      <Table
        className="mt-2"
        dataSource={data}
        columns={columns}
        loading={isLoading}
        pagination={false}
      />
      <Pagination
        className="mt-3"
        current={current}
        total={totalData}
        onChange={(page, size) => {
          getData(size, page);
          setCurrent(page);
        }}
      />

      {/* for modal */}
      <Modal
        title="Confirmation"
        open={open}
        onOk={handleAdd}
        onCancel={hideModal}
        okText="Send"
      >
        <Form layout="vertical" className="row-col">
          <Form.Item label="Send now?" name="name">
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              checked={checked}
              onChange={(e) => setChecked(!checked)}
            />
          </Form.Item>
          {checked ? (
            ""
          ) : (
            <Form.Item label="Date:" name="name">
              <DatePicker
                format="YYYY-MM-DD HH:mm"
                showTime
                onChange={onChange}
              />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default Notification;
