import { Button, Col, Form, Input, message, Row } from "antd";
import Title from "antd/es/typography/Title";
import Text from "antd/es/typography/Text";
import { DatePicker } from "antd";
import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Navigation from "../../components/layout/Navigation";

const AddQuiz = () => {
  const { RangePicker } = DatePicker;
  const navigate = useNavigate();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [form, setForm] = useState({
    title: "",
    duration: 0,
  });

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const onChange = (dateString) => {
    setStartTime(Math.floor(new Date(dateString[0]).getTime() / 1000));
    setEndTime(Math.floor(new Date(dateString[1]).getTime() / 1000));
  };

  const handleAdd = () => {
    const token = localStorage.getItem("token");
    axios
      .post(
        `${process.env.REACT_APP_URL}cultureapp/quiz/add`,
        { ...form, timestart: startTime, timeend: endTime },
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        navigate("/quiz");
        message.success("You have successfully submitted!");
      })
      .catch((err) => {
        message.error("Error! Try again.");
      });
  };

  return (
    <div className="form-question">
      <Navigation
        link="/quiz"
        title="Quiz"
        link2="/add-quiz"
        title2="Add Quiz"
      />
      <Title>Add Quiz</Title>
      <Form layout="vertical" className="row-col form-question">
        <Form.Item label="Title:" name="title">
          <Input
            type="text"
            name="title"
            className="w-95"
            placeholder="title"
            value={form.name}
            onChange={handleChange}
          />
        </Form.Item>
        <Row>
          <Col span={12}>
            <Form.Item label="Date:" name="date">
              <RangePicker
                className="p-3"
                initialValue={new Date()}
                showTime={{
                  format: "HH:mm",
                }}
                format="YYYY-MM-DD HH:mm"
                onChange={onChange}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Duration(minute):"
              name="duration"
              className="duration"
            >
              <Input
                type="number"
                name="duration"
                className="p-3"
                style={{ width: "90%" }}
                placeholder="duration"
                value={form.duration}
                onChange={handleChange}
              />
            </Form.Item>
            <Text className="text-secondary">
              note: '0' for unlimited duration
            </Text>
          </Col>
        </Row>
      </Form>
      <Button type="primary w-95 btn-submit mt-2" onClick={handleAdd}>
        Submit
      </Button>
    </div>
  );
};

export default AddQuiz;
