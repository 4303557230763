import { Button, Form, Input, message, Select } from "antd";
import Title from "antd/es/typography/Title";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navigation from "../../components/layout/Navigation";

const AddQuestionBankQuiz = () => {
  const navigate = useNavigate();
  const [answer, setAnswer] = useState("");
  const token = localStorage.getItem("token");
  const [form, setForm] = useState({
    question: "",
    option_a: "",
    option_b: "",
    option_c: "",
    option_d: "",
  });

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleAdd = () => {
    axios
      .post(
        `${process.env.REACT_APP_URL}cultureapp/questionbank/add`,
        { ...form, correctanswer: answer },
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        navigate("/quiz/question-bank-quiz");
        message.success("You have successfully submitted!");
      })
      .catch((err) => {
        message.error("Error! Try again.");
      });
  };

  return (
    <div className="form-question">
      <Navigation
        link="/quiz"
        title="Quiz"
        link2="/quiz/question-bank-quiz"
        title2="Question Bank Quiz"
        link3="/quiz/add-question-bank-quiz"
        title3="Add Question Bank Quiz"
      />
      <Title>Add Question Bank Quiz</Title>
      <Form layout="vertical" className="row-col form-question">
        <Form.Item label="Question:" name="question">
          <Input
            type="text"
            name="question"
            className="w-95"
            placeholder="question"
            value={form.question}
            onChange={handleChange}
          />
        </Form.Item>
        <Form.Item label="Option a:" name="option_a">
          <Input
            type="text"
            name="option_a"
            className="w-95"
            placeholder="option a"
            value={form.option_a}
            onChange={handleChange}
          />
        </Form.Item>
        <Form.Item label="Option b:" name="option_b">
          <Input
            type="text"
            name="option_b"
            className="w-95"
            placeholder="option b"
            value={form.option_b}
            onChange={handleChange}
          />
        </Form.Item>
        <Form.Item label="Option c:" name="option_c">
          <Input
            name="option_c"
            className="w-95"
            placeholder="option c"
            value={form.option_c}
            onChange={handleChange}
          />
        </Form.Item>
        <Form.Item label="Option d:" name="option_d">
          <Input
            type="text"
            name="option_d"
            className="w-95"
            placeholder="option d"
            value={form.option_d}
            onChange={handleChange}
          />
        </Form.Item>
        <Form.Item label="Correct answer:" name="correctanswer">
          <Select
            name="correctanswer"
            style={{ width: "95%" }}
            placeholder="correct answer"
            value={answer}
            onChange={(value) => setAnswer(value)}
            options={[
              {
                value: "a",
                label: "a",
              },
              {
                value: "b",
                label: "b",
              },
              {
                value: "c",
                label: "c",
              },
              {
                value: "d",
                label: "d",
              },
            ]}
          />
        </Form.Item>
      </Form>
      <Button type="primary btn-submit" onClick={handleAdd}>
        Submit
      </Button>
    </div>
  );
};

export default AddQuestionBankQuiz;
