import { Breadcrumb } from "antd";
import React from "react";

const Navigation = ({ link, title, link2, title2, link3, title3, link4, title4 }) => {
  return (
    <Breadcrumb
      separator=">"
      style={{
        margin: "15px 0px",
      }}
    >
      <Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
      <Breadcrumb.Item href={link} className="text-dark">
        {title}
      </Breadcrumb.Item>
      <Breadcrumb.Item href={link2} className="text-dark">
        {title2}
      </Breadcrumb.Item>
      <Breadcrumb.Item href={link3} className="text-dark">
        {title3}
      </Breadcrumb.Item>
      <Breadcrumb.Item href={link4} className="text-dark">
        {title4}
      </Breadcrumb.Item>
    </Breadcrumb>
  );
};

export default Navigation;
