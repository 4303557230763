import { Button, Form, Input, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import Title from "antd/es/typography/Title";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navigation from "../../components/layout/Navigation";

const AddNotification = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [form, setForm] = useState({
    title: "",
    body: "",
  });

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    axios
      .post(`${process.env.REACT_APP_URL}cultureapp/notification/add`, form, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        navigate("/notification");
        message.success("You have successfully submitted!");
      })
      .catch((err) => {
        message.error("Error! Try again.");
      });
  };

  return (
    <>
      <Navigation
        link="/notification"
        title="Notification"
        link2="/notification/add-notification"
        title2="Add Notification"
      />
      <Title>Add Notification</Title>
      <Form layout="vertical" className="row-col">
        <Form.Item label="Title:" name="title">
          <Input
            type="text"
            name="title"
            className="w-95"
            placeholder="title"
            value={form.title}
            onChange={handleChange}
          />
        </Form.Item>
        <Form.Item label="Description:" name="body">
          <TextArea
            type="text"
            name="body"
            className="w-95"
            placeholder="description"
            value={form.body}
            onChange={handleChange}
          />
        </Form.Item>
      </Form>
      <Button type="primary w-95 btn-submit" onClick={handleSubmit}>
        Submit
      </Button>
    </>
  );
};

export default AddNotification;
