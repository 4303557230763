import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Space,
  Table,
  Upload,
} from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import { ModalDelete } from "../../components/layout/ModalDelete";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import axios from "axios";
import { AddButton } from "../../components/layout/AddButton";
import Navigation from "../../components/layout/Navigation";

const CaseStudy = () => {
  const [open, setOpen] = useState(false);
  const [dataCase, setDataCase] = useState([""]);
  const [isLoading, setIsLoading] = useState(true);
  const [modal, contextHolder] = Modal.useModal();
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const [title, setTitle] = useState("");
  const [file, setFile] = useState(null);

  const hideModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_URL}cultureapp/corevalues/casestudies/list/${id}`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        setDataCase(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        alert(err);
      });
  }, [token, id]);

  const handleUploadPDF = () => {
    const PPData = new FormData();
    PPData.append("file", file);
    PPData.append("idvalue", id);
    PPData.append("title", title);
    axios
      .post(
        `${process.env.REACT_APP_URL}cultureapp/corevalues/casestudies/add`,
        PPData,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        window.location.reload(false);
        message.success("You have successfully submitted!");
      })
      .catch((err) => {
        message.error("Error! Try again.");
      });
  };

  const data = dataCase.map((row) => ({
    no: row.number,
    id: row.id,
    title: row.title,
    file: row.file,
  }));

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "5%",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Action",
      key: "action",
      width: "15%",
      render: (_, record) => (
        <Space size="middle">
          <Button className="outline-0" href={record.file} target="_blank">
            <InfoCircleOutlined />
          </Button>
          <ModalDelete
            url="corevalues/casestudies"
            id={record.id}
          ></ModalDelete>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Navigation link="/core-values" title="Core Values" title2="Case Study"/>
      <div className="title">
        <Title level={2}>Case Study</Title>
        <AddButton children="file" click={() => setOpen(true)} />
      </div>
      <Table dataSource={data} columns={columns} loading={isLoading} />

      {/* for modal */}
      <Modal title="Add file" open={open} onCancel={hideModal} onOk={handleUploadPDF}>
        <Form layout="vertical" className="row-col form-question">
          <Form.Item label="Title:" name="title">
            <Input
              type="text"
              name="title"
              className="w-95"
              placeholder="Title"
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item label="Upload file:" name="file">
            <Input
              type="file"
              name="file"
              className="w-95"
              onChange={(e) => {
                setFile(e.target.files[0]);
                e.preventDefault();
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
      {contextHolder}
    </>
  );
};

export default CaseStudy;
