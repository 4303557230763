import { Button, Space, Table } from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import { ModalDelete } from "../../components/layout/ModalDelete";
import { InfoCircleOutlined, EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AddButton } from "../../components/layout/AddButton";
import Navigation from "../../components/layout/Navigation";

const CoreValues = () => {
  const navigate = useNavigate();
  const [dataValue, setDataValue] = useState([""]);
  const [isLoading, setIsLoading] = useState(true);
  const token = localStorage.getItem("token");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL}cultureapp/corevalues/list`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        setDataValue(res.data.data);
        setIsLoading(false);
      });
  }, [token]);

  const data = dataValue.map((row) => ({
    id: row.id,
    no: row.number,
    icon: row.icon,
    name: row.name,
  }));

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "5%",
    },
    {
      title: "Icon",
      dataIndex: "icon",
      key: "icon",
      width:"15%",
      render: (_, record) => <img alt="" src={record.icon} width={100}></img>
    },
    {
      title: "Value Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Action",
      key: "action",
      width: "15%",
      render: (_, record) => (
        <Space size="middle">
          <Button
            className="outline-0"
            onClick={() => navigate(`/core-values/update-value/${record.id}`)}
          >
            <EditOutlined />
          </Button>
          <ModalDelete url="corevalues" id={record.id}></ModalDelete>
          <Button
            className="outline-0"
            onClick={() => navigate(`/core-values/case-study/${record.id}`)}
          >
            <InfoCircleOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Navigation link="/core-values" title="Core Values" />
      <div className="title">
        <Title level={2}>Core Values</Title>
        <AddButton children="core value" click={() => navigate("/core-values/add-value")} />
      </div>
      <Table dataSource={data} columns={columns} loading={isLoading} />
    </>
  );
};

export default CoreValues;
