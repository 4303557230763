import { Button, Pagination, Space, Table } from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import { ModalDelete } from "../../components/layout/ModalDelete";
import { EditOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { AddButton } from "../../components/layout/AddButton";
import { useNavigate } from "react-router-dom";
import Navigation from "../../components/layout/Navigation";
import axios from "axios";
import DOMPurify from "dompurify";
import Search from "antd/es/input/Search";

const News = () => {
  const navigate = useNavigate();
  const [news, setNews] = useState([""]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalData, setTotalData] = useState(0);
  const [current, setCurrent] = useState(1);
  const token = localStorage.getItem("token");

  const getData = (pageSize, pageIndex, searchIndex) => {
    axios
      .get(
        `${process.env.REACT_APP_URL}cultureapp/news/list/${pageSize ?? 10}/${
          pageIndex ?? 1
        }`,
        {
          headers: { Authorization: "Bearer " + token },
          params: { search: searchIndex },
        }
      )
      .then((res) => {
        setNews(res.data.data);
        setTotalData(res.data.totaldata);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const data = news.map((row) => ({
    id: row.id,
    no: row.number,
    banner: row.banner,
    title: row.title,
    description: row.body,
  }));

  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "5%",
    },
    {
      title: "Image",
      dataIndex: "banner",
      key: "banner",
      render: (_, record) => <img alt="" src={record.banner} width={150}></img>,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (record) => (
        <div
          className="preview"
          dangerouslySetInnerHTML={createMarkup(record)}
        ></div>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "15%",
      render: (_, record) => (
        <Space size="middle">
          <Button
            className="outline-0"
            onClick={() => navigate(`/news/detail-news/${record.id}`)}
          >
            <InfoCircleOutlined />
          </Button>
          <Button
            className="outline-0"
            onClick={() => navigate(`/news/update-news/${record.id}`)}
          >
            <EditOutlined />
          </Button>
          <ModalDelete url="news" id={record.id}></ModalDelete>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Navigation link="/news" title="News" />
      <Title level={2}>News</Title>
      <div className="title">
        <Search
          placeholder="Search Name"
          onSearch={(value) => {
            getData(10, 1, value);
          }}
          style={{ width: "60%" }}
          allowClear
        />
        <div>
          <AddButton children="news" click={() => navigate("/news/add-news")} />
        </div>
      </div>
      <Table
        className="mt-2"
        dataSource={data}
        columns={columns}
        loading={isLoading}
        pagination={false}
      />
      <Pagination
        className="mt-3"
        current={current}
        total={totalData}
        onChange={(page, size) => {
          getData(size, page);
          setCurrent(page);
        }}
      />
    </>
  );
};

export default News;
