import { Button, Space, Switch, Table, message, Pagination } from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import { ModalDelete } from "../../components/layout/ModalDelete";
import {
  InfoCircleOutlined,
  QuestionCircleOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { AddButton } from "../../components/layout/AddButton";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Navigation from "../../components/layout/Navigation";
import Search from "antd/es/input/Search";
import "chart.js/auto";
import { Bar } from "react-chartjs-2";

const DetailSurvey = () => {
  const [dataSurvey, setDataSurvey] = useState([""]);
  const [dataQuestionSurvey, setDataQuestionSurvey] = useState([""]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalData, setTotalData] = useState(0);
  const [totalDataQuestion, setTotalDataQuestion] = useState(0);
  const [current, setCurrent] = useState(1);
  const { id } = useParams();
  const token = localStorage.getItem("token");

  const getData = (pageSize, pageIndex, searchIndex) => {
    axios
      .get(
        `${
          process.env.REACT_APP_URL
        }cultureapp/survey/get_finished_user/${id}/${pageSize ?? 10}/${
          pageIndex ?? 1
        }`,
        {
          headers: { Authorization: "Bearer " + token },
          params: { search: searchIndex },
        }
      )
      .then((res) => {
        setDataSurvey(res.data.data);
        setTotalData(res.data.totaldata);
        setIsLoading(false);
      });
    axios
      .get(
        `${
          process.env.REACT_APP_URL
        }cultureapp/survey/get_survey_questionnaire/${id}/${pageSize ?? 10}/${
          pageIndex ?? 1
        }`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        setDataQuestionSurvey(res.data.data);
        setTotalDataQuestion(res.data.totaldata);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const labels = ["January", "February", "March", "April", "May"];

  const dataBar = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: [1, 2, 3, 4, 5],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  const options = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "right",
      },
      title: {
        display: true,
        text: "Chart.js Horizontal Bar Chart",
      },
    },
  };

  const dataQuestion = dataQuestionSurvey.map((row) => ({
    id: row.id,
    no: row.number,
    question: row.question,
  }));

  const columnsQuestion = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "5%",
    },
    {
      title: "Question",
      dataIndex: "question",
      key: "question",
      width: "15%",
    },
    {
      title: "Chart",
      dataIndex: "chart",
      key: "chart",
      render: () => (
        <Bar options={options} data={dataBar} className="h-100 w-100" />
      ),
    }
  ];

  const data = dataSurvey.map((row) => ({
    id: row.id,
    no: row.number,
    fullname: row.fullname,
    unit: row.unit,
    date: row.timefinished,
  }));

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "5%",
    },
    {
      title: "Fullname",
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
    },
    {
      title: "Jabatan",
      dataIndex: "jabatan",
      key: "jabatan",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
  ];

  return (
    <>
      <Navigation link="/survey" title="Survey" title2="Survey Detail" />
      <Title level={2}>Survey Detail</Title>

      <Search
        className="mt-2"
        placeholder="Search Name"
        onSearch={(value) => {
          getData(10, 1, value);
        }}
        style={{ width: "60%" }}
        allowClear
      />
      <Table
        className="mt-2"
        dataSource={dataQuestion}
        columns={columnsQuestion}
        loading={isLoading}
        pagination={false}
      />
      <Pagination
        className="mt-3"
        current={current}
        total={totalDataQuestion}
        onChange={(page, size) => {
          getData(size, page);
          setCurrent(page);
        }}
      />
      <Search
        className="mt-3"
        placeholder="Search Name"
        onSearch={(value) => {
          getData(10, 1, value);
        }}
        style={{ width: "60%" }}
        allowClear
      />
      <Table
        className="mt-2"
        dataSource={data}
        columns={columns}
        loading={isLoading}
        pagination={false}
      />
      <Pagination
        className="mt-3"
        current={current}
        total={totalData}
        onChange={(page, size) => {
          getData(size, page);
          setCurrent(page);
        }}
      />
    </>
  );
};

export default DetailSurvey;
