import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Pagination,
  Space,
  Table,
  Upload,
} from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import { ModalDelete } from "../../components/layout/ModalDelete";
import { InfoCircleOutlined, EditOutlined } from "@ant-design/icons";
import { AddButton } from "../../components/layout/AddButton";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Navigation from "../../components/layout/Navigation";
import Search from "antd/es/input/Search";

const Categories = () => {
  const navigate = useNavigate();
  const [dataCategories, setDataCategories] = useState([""]);
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [nameUpdate, setNameUpdate] = useState("");
  const [totalData, setTotalData] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [current, setCurrent] = useState(1);
  const [modalAdd, setModalAdd] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);
  const token = localStorage.getItem("token");

  const getData = (pageSize, pageIndex, searchIndex) => {
    axios
      .get(
        `${process.env.REACT_APP_URL}cultureapp/forum/categories/list/${
          pageSize ?? 10
        }/${pageIndex ?? 1}`,
        {
          headers: { Authorization: "Bearer " + token },
          params: { search: searchIndex },
        }
      )
      .then((res) => {
        setDataCategories(res.data.data);
        setTotalData(res.data.totaldata);
        setIsLoading(false);
      });
  };

  const handleSubmit = () => {
    axios
      .post(
        `${process.env.REACT_APP_URL}cultureapp/forum/categories/add`,
        { name: name },
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        setModalAdd(false);
        window.location.reload(false);
        message.success("You have successfully submitted!");
      })
      .catch((err) => {
        message.error("Error! Try again.");
      });
  };

  const handleUpdate = () => {
    axios
      .put(
        `${process.env.REACT_APP_URL}cultureapp/forum/categories/update/${id}`,
        { name: name ? name : nameUpdate },
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        setModalUpdate(false);
        window.location.reload(false);
        message.success("You have successfully updated!");
      })
      .catch((err) => {
        message.error("Error! Try again.");
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const data = dataCategories.map((row) => ({
    id: row.id,
    no: row.number,
    category: row.name,
  }));

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "5%",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Action",
      key: "action",
      width: "15%",
      render: (_, record) => (
        <Space size="middle">
          <Button
            className="outline-0"
            onClick={() => navigate(`/detail-categories/${record.id}`)}
          >
            <InfoCircleOutlined />
          </Button>
          <Button
            className="outline-0"
            onClick={() => {
              setId(record.id);
              setNameUpdate(record.category);
              setModalUpdate(true);
            }}
          >
            <EditOutlined />
          </Button>
          <ModalDelete url="forum/categories" id={record.id}></ModalDelete>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Navigation link="/categories" title="Categories" />
      <Title level={2}>Categories</Title>
      <div className="title">
        <Search
          placeholder="Search Name"
          onSearch={(value) => {
            getData(10, 1, value);
          }}
          style={{ width: "60%" }}
          allowClear
        />
        <AddButton children="category" click={() => setModalAdd(true)} />
      </div>
      <Table
        className="mt-2"
        dataSource={data}
        columns={columns}
        loading={isLoading}
        pagination={false}
      />
      <Pagination
        className="mt-3"
        current={current}
        total={totalData}
        onChange={(page, size) => {
          getData(size, page);
          setCurrent(page);
        }}
      />

      {/* for modal */}
      <Modal
        title="Add Category"
        open={modalAdd}
        onCancel={() => setModalAdd(false)}
        onOk={handleSubmit}
      >
        <Form layout="vertical">
          <Form.Item label="Category name:" name="title">
            <Input
              type="text"
              name="title"
              className="w-95"
              placeholder="name"
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Update Category"
        open={modalUpdate}
        onCancel={() => setModalUpdate(false)}
        onOk={handleUpdate}
      >
        <Form layout="vertical">
          <p>Category name:</p>
          <input
            type="text"
            name="title"
            className="w-95"
            placeholder="name"
            defaultValue={nameUpdate}
            onChange={(e) => setName(e.target.value)}
          />
        </Form>
      </Modal>
    </>
  );
};

export default Categories;
