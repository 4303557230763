import React from "react";
import { ExclamationCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import axios from "axios";
import { Button, Space, Modal, message } from "antd";

export const ModalDelete = ({ url, id }) => {
  const [modal, contextHolder] = Modal.useModal();
  const token = localStorage.getItem("token");

  const confirm = () => {
    modal.confirm({
      title: "Delete Confirmation",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure you want to delete this?",
      okText: "OK",
      cancelText: "Cancel",
      onOk: () => {
        axios
          .post(
            `${process.env.REACT_APP_URL}cultureapp/${url}/delete/${id}`,
            { id_value: id },
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          )
          .then((res) => {
            window.location.reload(false);
            message.success('You have succesfully deleted this!')
          })
          .catch((err) => {
            alert(err);
          });
      },
    });
  };

  return (
    <>
      <Space>
        <Button className="outline-0" onClick={confirm}>
          <DeleteOutlined />
        </Button>
      </Space>
      {contextHolder}
    </>
  );
};
