import { Button, Col, Form, Input, message, Row } from "antd";
import Title from "antd/es/typography/Title";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navigation from "../../components/layout/Navigation";

const AddUser = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [form, setForm] = useState({
    email: "",
    username: "",
    password: "",
    fullname: "",
    telp: "",
    unit: "",
  });

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    axios
      .post(`${process.env.REACT_APP_URL}cultureapp/usermanagement/add`, form, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        navigate("/user-management");
        message.success("You have successfully submitted!");
      })
      .catch((err) => {
        message.error("Error! Try again.");
      });
  };

  return (
    <>
      <Navigation
        link="/user-management"
        title="User Management"
        link2="/user-management/add-user"
        title2="Add User"
      />
      <Title>Add User</Title>
      <Form layout="vertical">
        <Row>
          <Col span={12}>
            <Form.Item label="Username:" name="username">
              <Input
                type="text"
                name="username"
                className="w-95"
                placeholder="username"
                value={form.username}
                onChange={handleChange}
              />
            </Form.Item>
            <Form.Item label="Fullname:" name="fullname">
              <Input
                type="text"
                name="fullname"
                className="w-95"
                placeholder="fullname"
                value={form.fullname}
                onChange={handleChange}
              />
            </Form.Item>
            <Form.Item label="Email:" name="email">
              <Input
                type="text"
                name="email"
                className="w-95"
                placeholder="email"
                value={form.email}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Telp:" name="telp">
              <Input
                type="number"
                name="telp"
                className="w-95"
                placeholder="telp"
                value={form.telp}
                onChange={handleChange}
              />
            </Form.Item>
            <Form.Item label="Unit:" name="unit">
              <Input
                type="text"
                name="unit"
                className="w-95"
                placeholder="unit"
                value={form.name}
                onChange={handleChange}
              />
            </Form.Item>
            <Form.Item label="Password:" name="password">
              <Input
                type="text"
                name="password"
                className="w-95"
                placeholder="password"
                value={form.password}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Button type="primary btn-submit" onClick={handleSubmit}>
        Submit
      </Button>
    </>
  );
};

export default AddUser;
