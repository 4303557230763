import { Button, message, Pagination, Space, Table, Upload } from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import { ModalDelete } from "../../components/layout/ModalDelete";
import { InfoCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { AddButton } from "../../components/layout/AddButton";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Navigation from "../../components/layout/Navigation";
import Search from "antd/es/input/Search";

const DetailCategories = () => {
  const navigate = useNavigate();
  const [dataForum, setDataForum] = useState([""]);
  const [dataCategory, setDataCategory] = useState("");
  const [totalData, setTotalData] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [current, setCurrent] = useState(1);
  const { id } = useParams();
  const token = localStorage.getItem("token");

  const getData = (pageSize, pageIndex, searchIndex) => {
    axios
      .get(
        `${
          process.env.REACT_APP_URL
        }cultureapp/forum/list_detail_category/${id}/${pageSize ?? 10}/${
          pageIndex ?? 1
        }`,
        {
          headers: { Authorization: "Bearer " + token },
          params: { search: searchIndex },
        }
      )
      .then((res) => {
        setDataForum(res.data.data);
        setTotalData(res.data.totaldata);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_URL}cultureapp/forum/categories/detail/${id}`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        setDataCategory(res.data);
        setIsLoading(false);
      });
    getData();
  }, []);

  const data = dataForum.map((row) => ({
    id: row.id,
    no: row.number,
    author: row.author,
    title: row.title,
    total_responses: row.total_responses,
    total_views: row.total_views,
    total_likes: row.total_likes,
    date: row.timecreated,
  }));

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "5%",
    },
    {
      title: "Author",
      dataIndex: "author",
      key: "author",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Total Responses",
      dataIndex: "total_responses",
      key: "total_responses",
    },
    {
      title: "Total View",
      dataIndex: "total_views",
      key: "total_views",
    },
    {
      title: "Total Like",
      dataIndex: "total_likes",
      key: "total_likes",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Action",
      key: "action",
      width: "15%",
      render: (_, record) => (
        <Space size="middle">
          <Button
            className="outline-0"
            onClick={() => navigate(`/detail-forum/${record.id}`)}
          >
            <InfoCircleOutlined />
          </Button>
          <ModalDelete url="questionbank" id={record.id}></ModalDelete>
        </Space>
      ),
    },
  ];
  return (
    <>
      <Navigation
        link="/categories"
        title="Categories"
        title2="Category Detail Forum"
      />
      <Title level={2}>Forum {dataCategory.name}</Title>
      <div className="title">
        <Search
          placeholder="Search Name"
          onSearch={(value) => {
            getData(10, 1, value);
          }}
          style={{ width: "60%" }}
          allowClear
        />
        <AddButton
          children="forum"
          click={() => navigate(`/add-forum/${id}`)}
        />
      </div>
      <Table
        className="mt-2"
        dataSource={data}
        columns={columns}
        loading={isLoading}
        pagination={false}
      />
      <Pagination
        className="mt-3"
        current={current}
        total={totalData}
        onChange={(page, size) => {
          getData(size, page);
          setCurrent(page);
        }}
      />
    </>
  );
};

export default DetailCategories;
