import { Dropdown, Layout, Menu, message } from "antd";
import { Footer } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  UserOutlined,
  DashboardOutlined,
  SettingOutlined,
  NotificationOutlined,
  CommentOutlined,
  PaperClipOutlined,
  MessageOutlined,
  TeamOutlined,
  ProfileOutlined,
  ReadOutlined,
  InboxOutlined,
  BookOutlined,
  ReconciliationOutlined,
  FileTextOutlined,
  AppstoreOutlined,
} from "@ant-design/icons";
import SubMenu from "antd/es/menu/SubMenu";
import logo from "../../../assets/logo-bumn-alternatif.jpg"
const { Header, Sider, Content } = Layout;

export default function MainLayout({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [current, setCurrent] = useState(location.pathname);

  useEffect(() => {
    if (location) {
      if (current !== location.pathname) {
        setCurrent(location.pathname);
      }
    }
  }, [location, current]);

  const currentMenuKey = (key) => {
    if (location.pathname.search(key) >= 0) {
      return location.pathname;
    } else {
      return key;
    }
  };

  const items = [
    {
      label: (
        <a
          onClick={() => {
            navigate("/login");
            localStorage.clear();
            message.info("You have been logged out!");
          }}
        >
          Log out
        </a>
      ),
      key: "3",
    },
  ];

  return (
    <Layout className="layout">
      <Sider className="site-sider">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            width={120}
            src={logo}
            className="mt-3"
            alt=""
          />
        </div>
        <Menu
          className="mt-3 ant-layout-sider text-white"
          theme="dark"
          mode="inline"
          selectedKeys={[current]}
          onClick={(e) => setCurrent(e.key)}
        >
          <Menu.Item
            key="/"
            onClick={() => navigate("/")}
            icon={<DashboardOutlined />}
          >
            Dashboard
          </Menu.Item>
          <SubMenu
            key="/upload-content"
            title="Content Management"
            icon={<AppstoreOutlined />}
          >
            <Menu.Item
              key={currentMenuKey("/upload-content")}
              onClick={() => navigate("/upload-content")}
              icon={<PaperClipOutlined />}
            >
              Upload Content
            </Menu.Item>
            <Menu.Item
              key={currentMenuKey("/core-values")}
              onClick={() => navigate("/core-values")}
              icon={<BookOutlined />}
            >
              Core Values
            </Menu.Item>
            <Menu.Item
              key={currentMenuKey("/quiz")}
              onClick={() => navigate("/quiz")}
              icon={<ReconciliationOutlined />}
            >
              Quiz
            </Menu.Item>
            <Menu.Item
              key={currentMenuKey("/news")}
              onClick={() => navigate("/news")}
              icon={<ReadOutlined />}
            >
              News
            </Menu.Item>
            {/* <Menu.Item
              key={currentMenuKey("/survey")}
              onClick={() => navigate("/survey")}
              icon={<FileTextOutlined />}
            >
              Survey
            </Menu.Item> */}
            {/* <SubMenu key="14" title="Forum" icon={<CommentOutlined />}>
              <Menu.Item
                key="/categories"
                onClick={() => navigate("/categories")}
                icon={<MessageOutlined />}
              >
                Categories
              </Menu.Item>
              <Menu.Item
                key="/report"
                onClick={() => navigate("/report")}
                icon={<ProfileOutlined />}
              >
                Report
              </Menu.Item>
            </SubMenu> */}
          </SubMenu>
          <Menu.Item
            key={currentMenuKey("/notification")}
            onClick={() => navigate("/notification")}
            icon={<NotificationOutlined />}
          >
            Notification
          </Menu.Item>
          <Menu.Item
            key={currentMenuKey("/tweet-values")}
            onClick={() => navigate("/tweet-values")}
            icon={<InboxOutlined />}
          >
            Tweet Values
          </Menu.Item>
          <Menu.Item
            key={currentMenuKey("/user-management")}
            onClick={() => navigate("/user-management")}
            icon={<TeamOutlined />}
          >
            User Management
          </Menu.Item>
          <Menu.Item
            key={currentMenuKey("/settings")}
            onClick={() => navigate("/settings")}
            icon={<SettingOutlined />}
          >
            Settings
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            padding: "1.5rem",
          }}
        >
          <div className="dropdown-profile">
            <Dropdown
              menu={{ items }}
              trigger={["click"]}
              placement="bottom"
              arrow={{ pointAtCenter: true }}
              className=""
            >
              <UserOutlined style={{ fontSize: "18px" }} />
            </Dropdown>
          </div>
        </Header>
        <Content
          className="site-layout-background site-content"
          style={{
            margin: "16px 16px",
            padding: 24,
            overflow: "initial",
          }}
        >
          {children} <Outlet />
        </Content>
        <Footer
          style={{
            textAlign: "center",
          }}
        >
          Copyright © Digital Learning Solutions 2023. All rights reserved. v
          1.0..0
        </Footer>
      </Layout>
    </Layout>
  );
}
