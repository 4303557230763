import React, { useEffect } from "react";
import { EditOutlined, InfoCircleOutlined } from "@ant-design/icons";
import Navigation from "../../components/layout/Navigation";
import Title from "antd/es/typography/Title";
import { Button, Form, Input, message, Modal, Space, Table } from "antd";
import { useState } from "react";
import axios from "axios";

const UploadContent = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [modalVideo, setModalVideo] = useState(false);
  const [modalVisionMission, setModalVisionMission] = useState(false);
  const [modalCode, setModalCode] = useState(false);
  const [modalPocket, setModalPocket] = useState(false);
  const [video, setVideo] = useState([""]);
  const [visionMission, setVisionMission] = useState([""]);
  const [code, setCode] = useState([""]);
  const [pocket, setPocket] = useState([""]);
  const [fileVideo, setFileVideo] = useState("");
  const [fileVisionMission, setFileVissionMission] = useState("");
  const [imageVisionMission, setImageVisionMission] = useState("");
  const [fileCode, setFileCode] = useState("");
  const [filePocket, setFilePocket] = useState("");
  const [idVisionMission, setIdVisionMission] = useState("");
  const token = localStorage.getItem("token");

  const hideModal = () => {
    setModalVideo(false);
    setModalVisionMission(false);
    setModalCode(false);
    setModalPocket(false);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL}cultureapp/uploadcontent/home_video`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        setVideo(res.data.data);
        setIsLoading(false);
      });
    axios
      .get(
        `${process.env.REACT_APP_URL}cultureapp/uploadcontent/vision_mission`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        setVisionMission(res.data.data);
        setIsLoading(false);
      });
    axios
      .get(
        `${process.env.REACT_APP_URL}cultureapp/uploadcontent/code_conduct`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        setCode(res.data.data);
        setIsLoading(false);
      });
    axios
      .get(`${process.env.REACT_APP_URL}cultureapp/uploadcontent/pocket_book`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        setPocket(res.data.data);
        setIsLoading(false);
      });
  }, []);

  const handleUploadVideo = () => {
    const PPData = new FormData();
    PPData.append("video", fileVideo);
    axios
      .put(
        `${process.env.REACT_APP_URL}cultureapp/uploadcontent/update_home_video/1`,
        PPData,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        window.location.reload(false);
        message.success("You have successfully uploaded!");
      })
      .catch((err) => {
        message.error("Error! Try again.");
      });
  };

  const handleUploadVisionMission = () => {
    const PPData = new FormData();
    PPData.append("image", imageVisionMission ? imageVisionMission : "");
    PPData.append("pdf", fileVisionMission);
    axios
      .put(
        `${process.env.REACT_APP_URL}cultureapp/uploadcontent/update_vision_mission/${idVisionMission}`,
        PPData,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        window.location.reload(false);
        message.success("You have successfully uploaded!");
      })
      .catch((err) => {
        message.error("Error! Try again.");
      });
  };

  const handleUploadCode = () => {
    const PPData = new FormData();
    PPData.append("pdf", fileCode);
    axios
      .put(
        `${process.env.REACT_APP_URL}cultureapp/uploadcontent/update_code_conduct/1`,
        PPData,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        window.location.reload(false);
        message.success("You have successfully uploaded!");
      })
      .catch((err) => {
        message.error("Error! Try again.");
      });
  };

  const handleUploadPocket = () => {
    const PPData = new FormData();
    PPData.append("pdf", filePocket);
    axios
      .put(
        `${process.env.REACT_APP_URL}cultureapp/uploadcontent/update_pocket_book/1`,
        PPData,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        window.location.reload(false);
        message.success("You have successfully uploaded!");
      })
      .catch((err) => {
        message.error("Error! Try again.");
      });
  };

  const dataVideo = video.map((row) => ({
    id: row.id,
    file: row.filename,
    link: row.link,
  }));

  const dataVisionMission = visionMission.map((row) => ({
    id: row.id,
    name: row.name,
    image: row.file_image,
    pdf: row.file_pdf,
  }));

  const dataCode = code.map((row) => ({
    id: row.id,
    file: row.filename,
    link: row.link,
  }));

  const dataPocket = pocket.map((row) => ({
    id: row.id,
    file: row.filename,
    link: row.link,
  }));

  const columnsVideo = [
    {
      title: "File",
      key: "file",
      dataIndex: "file",
    },
    {
      title: "Action",
      key: "action",
      width: "15%",
      render: (_, record) => (
        <Space size="middle">
          <Button className="outline-0" href={record.link} target="_blank">
            <InfoCircleOutlined />
          </Button>
          <Button
            className="outline-0"
            onClick={() => {
              setModalVideo(true);
            }}
          >
            <EditOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const columnsVisionMission = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Image",
      key: "image",
      dataIndex: "image",
      render: (_, record) => <img alt="" src={record.image} width={170}></img>,
    },
    {
      title: "Action",
      key: "action",
      width: "15%",
      render: (_, record) => (
        <Space size="middle">
          <Button className="outline-0" href={record.pdf} target="_blank">
            <InfoCircleOutlined />
          </Button>
          <Button
            className="outline-0"
            onClick={() => {
              setModalVisionMission(true);
              setIdVisionMission(record.id);
            }}
          >
            <EditOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const columnsCode = [
    {
      title: "File",
      key: "file",
      dataIndex: "file",
    },
    {
      title: "Action",
      key: "action",
      width: "15%",
      render: (_, record) => (
        <Space size="middle">
          <Button className="outline-0" href={record.link} target="_blank">
            <InfoCircleOutlined />
          </Button>
          <Button
            className="outline-0"
            onClick={() => {
              setModalCode(true);
            }}
          >
            <EditOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const columnsPocket = [
    {
      title: "File",
      key: "file",
      dataIndex: "file",
    },
    {
      title: "Action",
      key: "action",
      width: "15%",
      render: (_, record) => (
        <Space size="middle">
          <Button className="outline-0" href={record.link} target="_blank">
            <InfoCircleOutlined />
          </Button>
          <Button
            className="outline-0"
            onClick={() => {
              setModalPocket(true);
            }}
          >
            <EditOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Navigation link="/upload-content" title="Upload Content" />
      <Title>Upload Content</Title>
      <Title level={4} className="text-center mt-3">
        Home Video
      </Title>
      <Table
        className=""
        dataSource={dataVideo}
        columns={columnsVideo}
        loading={isLoading}
        pagination={false}
      />
      <Title level={4} className="text-center mt-3">
        Vision & Mission
      </Title>
      <Table
        className=""
        dataSource={dataVisionMission}
        columns={columnsVisionMission}
        loading={isLoading}
        pagination={false}
      />
      <Title level={4} className="text-center mt-3">
        Code of Conduct
      </Title>
      <Table
        className=""
        dataSource={dataCode}
        columns={columnsCode}
        loading={isLoading}
        pagination={false}
      />
      <Title level={4} className="text-center mt-3">
        Pocket Book
      </Title>
      <Table
        className=""
        dataSource={dataPocket}
        columns={columnsPocket}
        loading={isLoading}
        pagination={false}
      />

      {/* for modal */}
      <Modal
        title="Update Home Video"
        open={modalVideo}
        onCancel={hideModal}
        onOk={handleUploadVideo}
      >
        <Form layout="vertical">
          <Form.Item label="Upload file:" name="file">
            <Input
              type="file"
              name="file"
              className="w-95"
              onChange={(e) => {
                setFileVideo(e.target.files[0]);
                e.preventDefault();
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Update Vision & Mission"
        open={modalVisionMission}
        onCancel={hideModal}
        onOk={handleUploadVisionMission}
      >
        <Form layout="vertical">
          <Form.Item label="Upload image:" name="image">
            <Input
              type="file"
              name="image"
              className="w-95"
              onChange={(e) => {
                setImageVisionMission(e.target.files[0]);
                e.preventDefault();
              }}
            />
          </Form.Item>
          <Form.Item label="Upload file:" name="file">
            <Input
              type="file"
              name="file"
              className="w-95"
              onChange={(e) => {
                setFileVissionMission(e.target.files[0]);
                e.preventDefault();
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Update Code of Conduct"
        open={modalCode}
        onCancel={hideModal}
        onOk={handleUploadCode}
      >
        <Form layout="vertical">
          <Form.Item label="Upload file:" name="file">
            <Input
              type="file"
              name="file"
              className="w-95"
              onChange={(e) => {
                setFileCode(e.target.files[0]);
                e.preventDefault();
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Update Pocket Book"
        open={modalPocket}
        onCancel={hideModal}
        onOk={handleUploadPocket}
      >
        <Form layout="vertical">
          <Form.Item label="Upload file:" name="file">
            <Input
              type="file"
              name="file"
              className="w-95"
              onChange={(e) => {
                setFilePocket(e.target.files[0]);
                e.preventDefault();
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UploadContent;
