import { Button, Col, Form, Input, message, Row } from "antd";
import Title from "antd/es/typography/Title";
import Text from "antd/es/typography/Text";
import { DatePicker } from "antd";
import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Navigation from "../../components/layout/Navigation";

const AddSurvey = () => {
  const { RangePicker } = DatePicker;
  const navigate = useNavigate();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [title, setTitle] = useState("");

  const onChange = (dateString) => {
    setStartTime(Math.floor(new Date(dateString[0]).getTime() / 1000));
    setEndTime(Math.floor(new Date(dateString[1]).getTime() / 1000));
  };

  const handleAdd = () => {
    const token = localStorage.getItem("token");
    axios
      .post(
        `${process.env.REACT_APP_URL}cultureapp/survey/add`,
        { title: title, timestart: startTime, timeend: endTime },
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        navigate("/survey");
        message.success("You have successfully submitted!");
      })
      .catch((err) => {
        message.error("Error! Try again.");
      });
  };

  return (
    <div className="form-question">
      <Navigation
        link="/survey"
        title="Survey"
        link2="/survey/add-survey"
        title2="Add Survey"
      />
      <Title>Add Survey</Title>
      <Form layout="vertical" className="row-col form-question">
        <Form.Item label="Title:" name="title">
          <Input
            type="text"
            name="title"
            className="w-95"
            placeholder="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Date:" name="date">
          <RangePicker
            className="p-3"
            initialValue={new Date()}
            showTime={{
              format: "HH:mm",
            }}
            format="YYYY-MM-DD HH:mm"
            onChange={onChange}
          />
        </Form.Item>
      </Form>
      <Button type="primary w-95 btn-submit mt-2" onClick={handleAdd}>
        Submit
      </Button>
    </div>
  );
};

export default AddSurvey;
