import { Button, Col, Form, Input, message, Row } from "antd";
import Title from "antd/es/typography/Title";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState } from "draft-js";
import { convertToHTML } from "draft-convert";
import Navigation from "../../components/layout/Navigation";

const AddValue = () => {
  const navigate = useNavigate();
  const [banner, setBanner] = useState(null);
  const [icon, setIcon] = useState(null);
  const token = localStorage.getItem("token");
  const [editorStateKataKunci, setEditorStateKataKunci] = useState(() =>
    EditorState.createEmpty()
  );
  const [editorStateKalimatAfirmasi, setEditorStateKalimatAfirmasi] = useState(
    () => EditorState.createEmpty()
  );
  const [editorStatePanduanPerilaku, setEditorStatePanduanPerilaku] = useState(
    () => EditorState.createEmpty()
  );

  const [convertedKataKunci, setConvertedKataKunci] = useState(null);
  const [convertedKalimatAfirmasi, setConvertedKalimatAfirmasi] =
    useState(null);
  const [convertedPanduanPerilaku, setConvertedPanduanPerilaku] =
    useState(null);

  useEffect(() => {
    const htmlKataKunci = convertToHTML(
      editorStateKataKunci.getCurrentContent()
    );
    const htmlKalimatAfirmasi = convertToHTML(
      editorStateKalimatAfirmasi.getCurrentContent()
    );
    const htmlPanduanPerilaku = convertToHTML(
      editorStatePanduanPerilaku.getCurrentContent()
    );
    setConvertedKataKunci(htmlKataKunci);
    setConvertedKalimatAfirmasi(htmlKalimatAfirmasi);
    setConvertedPanduanPerilaku(htmlPanduanPerilaku);
  }, [
    editorStateKataKunci,
    editorStateKalimatAfirmasi,
    editorStatePanduanPerilaku,
  ]);

  const [form, setForm] = useState({
    name: "",
    tagline: "",
  });

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleBanner = (e) => {
    e.preventDefault();
    setBanner(e.target.files[0]);
  };
  const handleIcon = (e) => {
    e.preventDefault();
    setIcon(e.target.files[0]);
  };

  const handleAdd = () => {
    const PPData = new FormData();
    PPData.append("icon", icon);
    PPData.append("banner", banner);
    PPData.append("name", form.name);
    PPData.append("tagline", form.tagline);
    PPData.append("katakunci", convertedKataKunci);
    PPData.append("kalimatafirmasi", convertedKalimatAfirmasi);
    PPData.append("panduanperilaku", convertedPanduanPerilaku);
    axios
      .post(`${process.env.REACT_APP_URL}cultureapp/corevalues/add`, PPData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        navigate("/core-values");
        message.success("You have successfully submitted!");
      })
      .catch((err) => {
        message.error("Error! Try again.");
      });
  };

  const toolbarOptions = {
    options: ["inline", "fontSize", "blockType", "list"],
    inline: {
      options: ["bold", "italic", "underline", "strikethrough"],
    },
    list: {
      options: ["unordered", "ordered"],
    },
    blockType: {
      options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
    },
  };

  return (
    <>
      <Navigation
        link="/core-values"
        title="Core Values"
        link2="/core-values/add-value"
        title2="Add Value"
      />
      <Title>Add Value</Title>
      <Form layout="vertical" className="row-col">
        <Row>
          <Col span={12}>
            <div>
              {banner ? (
                <img alt="" width={150} src={URL.createObjectURL(banner)} />
              ) : (
                ""
              )}
            </div>
            <Form.Item label="Set Image:" name="banner" className="mt-3">
              <Input
                type="file"
                name="banner"
                className="w-95"
                onChange={handleBanner}
              />
            </Form.Item>
            <div>
              {icon ? (
                <img alt="" src={URL.createObjectURL(icon)} width={100} />
              ) : (
                ""
              )}
            </div>
            <Form.Item label="Set Icon:" name="icon" className="mt-3">
              <Input
                type="file"
                name="icon"
                className="w-95"
                onChange={handleIcon}
              />
            </Form.Item>
            <Form.Item label="Value Name:" name="name">
              <Input
                type="text"
                name="name"
                className="w-95 p-2"
                placeholder="value name"
                value={form.name}
                onChange={handleChange}
              />
            </Form.Item>
            <Form.Item label="Tagline:" name="tagline">
              <Input
                type="text"
                name="tagline"
                className="w-95"
                placeholder="tagline"
                value={form.tagline}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Kata Kunci:" name="katakunci">
              <Editor
                editorState={editorStateKataKunci}
                onEditorStateChange={setEditorStateKataKunci}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                toolbar={toolbarOptions}
              />
            </Form.Item>
            <Form.Item label="Kalimat Afirmasi:" name="kalimatafirmasi">
              <Editor
                editorState={editorStateKalimatAfirmasi}
                onEditorStateChange={setEditorStateKalimatAfirmasi}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                toolbar={toolbarOptions}
              />
            </Form.Item>
            <Form.Item label="Panduan Perilaku:" name="panduanperilaku">
              <Editor
                editorState={editorStatePanduanPerilaku}
                onEditorStateChange={setEditorStatePanduanPerilaku}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                toolbar={toolbarOptions}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Button type="primary btn-submit" onClick={handleAdd}>
        Submit
      </Button>
    </>
  );
};

export default AddValue;
