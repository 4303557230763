import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import React from "react";

export const AddButton = ({children, click}) => {
  return (
    <>
      <Button type="primary" onClick={click}><PlusOutlined /> Add {children}</Button>
    </>
  );
};
