import { Button, message, Pagination, Space, Table, Upload } from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import { ModalDelete } from "../../components/layout/ModalDelete";
import { InfoCircleOutlined, UploadOutlined, EditOutlined } from "@ant-design/icons";
import { AddButton } from "../../components/layout/AddButton";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Navigation from "../../components/layout/Navigation";
import Search from "antd/es/input/Search";

const QuestionBankQuiz = () => {
  const navigate = useNavigate();
  const [dataQuestion, setDataQuestion] = useState([""]);
  const [totalData, setTotalData] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [current, setCurrent] = useState(1);
  const token = localStorage.getItem("token");

  const getData = (pageSize, pageIndex, searchIndex) => {
    axios
      .get(
        `${process.env.REACT_APP_URL}cultureapp/questionbank/list/${
          pageSize ?? 10
        }/${pageIndex ?? 1}`,
        {
          headers: { Authorization: "Bearer " + token },
          params: { search: searchIndex },
        }
      )
      .then((res) => {
        setDataQuestion(res.data.data);
        setTotalData(res.data.totaldata);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleUploadExcel = ({ e, fileList }) => {
    const PPData = new FormData();
    PPData.append("excelFile", fileList[0].originFileObj);
    axios
      .post(
        `${process.env.REACT_APP_URL}cultureapp/questionbank/upload_file_excel`,
        PPData,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        window.location.reload(false);
        message.success("You have successfully submitted!");
      })
      .catch((err) => {
        message.error("Error! Try again.");
      });
    e.preventDefault();
  };

  const data = dataQuestion.map((row) => ({
    id: row.id,
    no: row.number,
    question: row.question,
  }));

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "5%",
    },
    {
      title: "Question",
      dataIndex: "question",
      key: "question",
    },
    {
      title: "Action",
      key: "action",
      width: "15%",
      render: (_, record) => (
        <Space size="middle">
          <Button
            className="outline-0"
            onClick={() => navigate(`/update-question-quiz/${record.id}`)}
          >
            <EditOutlined />
          </Button>
          <ModalDelete url="questionbank" id={record.id}></ModalDelete>
        </Space>
      ),
    },
  ];
  return (
    <>
      <Navigation link="/quiz" title="Quiz" link2="/quiz/question-bank-quiz" title2="Question Bank Quiz" />
      <Title level={2}>Question Bank Quiz</Title>
      <div className="title">
        <Search
          placeholder="Search Name"
          onSearch={(value) => {
            getData(10, 1, value);
          }}
          style={{ width: "60%" }}
          allowClear
        />
        <div className="layout-button">
          <Upload
            previewFile={false}
            onChange={handleUploadExcel}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          >
            <Button type="primary" className="me-2">
              <UploadOutlined /> Upload excel
            </Button>
          </Upload>
          <AddButton
            children="question"
            click={() => navigate("/quiz/add-question-bank-quiz")}
          />
        </div>
      </div>
      <Table
        className="mt-2"
        dataSource={data}
        columns={columns}
        loading={isLoading}
        pagination={false}
      />
      <Pagination
        className="mt-3"
        current={current}
        total={totalData}
        onChange={(page, size) => {
          getData(size, page);
          setCurrent(page);
        }}
      />
    </>
  );
};

export default QuestionBankQuiz;