import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Pagination,
  Select,
  Space,
  Table,
} from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import {
  InfoCircleOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Option } from "antd/es/mentions";
import Navigation from "../../components/layout/Navigation";
import axios from "axios";
import { AddButton } from "../../components/layout/AddButton";
import { useNavigate, useParams } from "react-router-dom";

const QuestionQuizDetail = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [dataQuestionQuiz, setDataQuestionQuiz] = useState([""]);
  const [dataQuestionDetail, setDataQuestionDetail] = useState([""]);
  const [modal, contextHolder] = Modal.useModal();
  const [isLoading, setIsLoading] = useState(true);
  const [totalData, setTotalData] = useState(0);
  const [current, setCurrent] = useState(1);
  const { id } = useParams();
  const token = localStorage.getItem("token");

  const hideModal = () => {
    setOpen(false);
  };

  const getData = (pageSize, pageIndex) => {
    axios
      .get(
        `${process.env.REACT_APP_URL}cultureapp/quiz/get_quiz_question/${id}/${
          pageSize ?? 10
        }/${pageIndex ?? 1}`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        setDataQuestionQuiz(res.data.data);
        setTotalData(res.data.totaldata);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, [token, id]);

  const handleDelete = (idQuestion) => {
    modal.confirm({
      title: "Delete Confirmation",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure you want to delete this?",
      okText: "OK",
      cancelText: "Cancel",
      onOk: () => {
        axios
          .post(
            `${process.env.REACT_APP_URL}cultureapp/quiz/delete_quiz_question`,
            { id_quiz: id, id_question: idQuestion },
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          )
          .then((res) => {
            window.location.reload(false);
            message.success("You have succesfully deleted this!");
          })
          .catch((err) => {
            alert(err);
          });
      },
    });
  };

  const fetchDataQuestion = (id) => {
    axios
      .get(`${process.env.REACT_APP_URL}cultureapp/questionbank/detail/${id}`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        setDataQuestionDetail(res.data);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const data = dataQuestionQuiz.map((row) => ({
    id: row.id,
    no: row.number,
    question: row.question,
  }));

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "5%",
    },
    {
      title: "Question",
      dataIndex: "question",
      key: "question",
    },
    {
      title: "Action",
      key: "action",
      width: "15%",
      render: (_, record) => (
        <Space size="middle">
          <Button
            className="outline-0"
            onClick={() => {
              setOpen(true);
              fetchDataQuestion(record.id);
            }}
          >
            <InfoCircleOutlined />
          </Button>
          <Button className="outline-0" onClick={() => handleDelete(record.id)}>
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Navigation link="/quiz" title="Quiz" title2="Question Quiz Detail" />
      <div className="title">
        <Title level={2}>Question Quiz Detail</Title>
        <AddButton
          children="questions"
          click={() => navigate(`/quiz/add-question-quiz-detail/${id}`)}
        />
      </div>
      <Table
        dataSource={data}
        columns={columns}
        loading={isLoading}
        pagination={false}
      />
      <Pagination
        className="mt-3"
        current={current}
        total={totalData}
        onChange={(page, size) => {
          getData(size, page);
          setCurrent(page);
        }}
      />

      {/* for modal */}
      <Modal
        title="Question Detail"
        open={open}
        onCancel={hideModal}
        footer={null}
      >
        <Form
          layout="vertical"
          className="row-col form-question"
          fields={[
            {
              name: ["title"],
              value: dataQuestionDetail.question,
            },
            {
              name: ["option_a"],
              value: dataQuestionDetail.option_a,
            },
            {
              name: ["option_b"],
              value: dataQuestionDetail.option_b,
            },
            {
              name: ["option_c"],
              value: dataQuestionDetail.option_c,
            },
            {
              name: ["option_d"],
              value: dataQuestionDetail.option_d,
            },
            {
              name: ["correctanswer"],
              value: dataQuestionDetail.correctanswer,
            },
          ]}
          disabled={true}
        >
          <Form.Item label="Title:" name="title">
            <Input type="text" name="title" className="w-95" />
          </Form.Item>
          <Form.Item label="A:" name="option_a">
            <Input type="text" name="option_a" className="w-95" />
          </Form.Item>
          <Form.Item label="B:" name="option_b">
            <Input type="text" name="option_b" className="w-95" />
          </Form.Item>
          <Form.Item label="C:" name="option_c">
            <Input type="text" name="option_c" className="w-95" />
          </Form.Item>
          <Form.Item label="D:" name="option_d">
            <Input type="text" name="option_d" className="w-95" />
          </Form.Item>
          <Form.Item label="Answer:" name="correctanswer">
            <Select
              name="name"
              style={{ width: "95%" }}
              options={[
                {
                  value: "a",
                  label: "a",
                },
                {
                  value: "b",
                  label: "b",
                },
                {
                  value: "c",
                  label: "c",
                },
                {
                  value: "d",
                  label: "d",
                },
              ]}
            />
          </Form.Item>
        </Form>
      </Modal>
      {contextHolder}
    </>
  );
};

export default QuestionQuizDetail;
