import { Button, Form, message } from "antd";
import Title from "antd/es/typography/Title";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navigation from "../../components/layout/Navigation";

const UpdateNotification = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [dataNotification, setDataNotification] = useState("");
  const token = localStorage.getItem("token");
  const [form, setForm] = useState({
    title: "",
    body: "",
  });

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL}cultureapp/notification/detail/${id}`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        setDataNotification(res.data)
      })
      .catch((err) => {
        alert(err);
      });
  }, [token, id]);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdate = () => {
    axios
      .put(
        `${process.env.REACT_APP_URL}cultureapp/notification/update/${id}`,
        {
          title: form.title ? form.title : dataNotification?.title,
          body: form.body ? form.body : dataNotification?.body,
        },
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        navigate("/notification");
        message.success("You have successfully updated!");
      })
      .catch((err) => {
        message.error("Error! Try again.");
      });
  };

  return (
    <>
      <Navigation
        link="/notification"
        title="Notification"
        title2="Update Notification"
      />
      <Title>Update Notification</Title>
      <Form layout="vertical" className="row-col">
        <p>Title:</p>
        <input
          type="text"
          name="title"
          className="w-95"
          placeholder="title"
          defaultValue={dataNotification.title}
          onChange={handleChange}
        />
        <p>Description:</p>
        <textarea
          type="text"
          name="body"
          className="w-95"
          placeholder="description"
          defaultValue={dataNotification.body}
          onChange={handleChange}
        />
      </Form>
      <Button type="primary w-95 mt-2 btn-submit" onClick={handleUpdate}>
        Submit
      </Button>
    </>
  );
};

export default UpdateNotification;
