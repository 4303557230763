import {
  Button,
  Card,
  Col,
  Form,
  message,
  Modal,
  Pagination,
  Row,
  Table,
} from "antd";
import Title from "antd/es/typography/Title";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  StopOutlined,
  UnlockOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import Navigation from "../../components/layout/Navigation";
import "chart.js/auto";
import { Pie, Line } from "react-chartjs-2";
import { DatePicker } from "antd";
import dayjs from "dayjs";

const TweetValues = () => {
  const { RangePicker } = DatePicker;
  const [dataTweets, setDataTweets] = useState([""]);
  const [topValue, setTopValue] = useState([""]);
  const [topTweet, setTopTweet] = useState([""]);
  const [pieChart, setPieChart] = useState([""]);
  const [lineChart, setLineChart] = useState([""]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalData, setTotalData] = useState(0);
  const [current, setCurrent] = useState(1);
  const [tweetValues, setTweetValues] = useState("");
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [defEndTime, setDefEndTime] = useState();
  const [defStartTime, setDefStartTime] = useState();
  const [modal, contextHolder] = Modal.useModal();
  const token = localStorage.getItem("token");

  const getData = (pageSize, pageIndex) => {
    axios
      .get(
        `${process.env.REACT_APP_URL}cultureapp/tweetvalues/list/${
          pageSize ?? 10
        }/${pageIndex ?? 1}`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        setDataTweets(res.data.data);
        setTotalData(res.data.totaldata);
        setIsLoading(false);
      });
  };

  const getTimestamp30DaysAgo = () => {
    var d = new Date();
    var init = d.getMonth() == 0 ? 12 : 1;
    d.setMonth(d.getMonth() - init);
    var unixTimeStamp = Math.floor(d.getTime() / 1000);
    setDefStartTime(unixTimeStamp);
  };

  const getTimestampNow = () => {
    var d = new Date();
    var unixTimeStamp = Math.floor(d.getTime() / 1000);
    setDefEndTime(unixTimeStamp);
  };

  useEffect(() => {
    getTimestamp30DaysAgo();
    getTimestampNow();
    axios
      .get(`${process.env.REACT_APP_URL}cultureapp/tweetvalues/box_info`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        setTweetValues(res.data);
      });
    axios
      .get(`${process.env.REACT_APP_URL}cultureapp/tweetvalues/top_value`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        setTopValue(res.data.data);
      });
    axios
      .get(`${process.env.REACT_APP_URL}cultureapp/tweetvalues/top_tweet`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        setTopTweet(res.data.data);
      });
    axios
      .get(`${process.env.REACT_APP_URL}cultureapp/tweetvalues/pie_chart`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        setPieChart(res.data);
      });
    axios
      .get(
        `${process.env.REACT_APP_URL}cultureapp/tweetvalues/line_chart/${defStartTime}/${defEndTime}`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        setLineChart(res.data);
      });
    getData();
  }, [token, defEndTime, defStartTime]);

  const onChange = (dateString) => {
    setStartTime(dateString[0]);
    setEndTime(dateString[1]);
  };

  const convertedStartTime = Math.floor(new Date(startTime).getTime() / 1000);
  const convertedEndTime = Math.floor(new Date(endTime).getTime() / 1000);

  const getDataLineChart = () => {
    axios
      .get(
        `${process.env.REACT_APP_URL}cultureapp/tweetvalues/line_chart/${convertedStartTime}/${convertedEndTime}`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        setLineChart(res.data);
      });
  };

  const handleBlocked = (id, isBlocked) => {
    modal.confirm({
      title: "Confirmation",
      icon: <ExclamationCircleOutlined />,
      content:
        isBlocked === false
          ? "Are you sure you want to block this tweet?"
          : "Are you sure you want to unblock this tweet?",
      okText: "OK",
      cancelText: "Cancel",
      onOk: () => {
        if (isBlocked === false) {
          axios
            .post(
              `${process.env.REACT_APP_URL}cultureapp/tweetvalues/block_tweet/${id}`,
              { idtweet: id },
              {
                headers: { Authorization: "Bearer " + token },
              }
            )
            .then((res) => {
              window.location.reload(false);
              message.success("You have successfully blocked this tweet!");
            })
            .catch((err) => {
              message.error("Error! Try again.");
            });
        } else {
          axios
            .post(
              `${process.env.REACT_APP_URL}cultureapp/tweetvalues/unblock_tweet/${id}`,
              { idtweet: id },
              {
                headers: { Authorization: "Bearer " + token },
              }
            )
            .then((res) => {
              window.location.reload(false);
              message.success("You have successfully unblocked this tweet!");
            })
            .catch((err) => {
              message.error("Error! Try again.");
            });
        }
      },
    });
  };

  const dataPie = {
    labels: pieChart.label,
    datasets: [
      {
        // label: 'Rainfall',
        backgroundColor: [
          "#B21F00",
          "#C9DE00",
          "#2FDE00",
          "#00A6B4",
          "#6800B4",
        ],
        hoverBackgroundColor: [
          "#501800",
          "#4B5000",
          "#175000",
          "#003350",
          "#35014F",
        ],
        data: pieChart.data,
      },
    ],
  };

  const dataLine = {
    labels: lineChart.label,
    datasets: [
      {
        label: "First dataset",
        data: lineChart.data,
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
    options: {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    },
  };

  const dataTweet = dataTweets.map((row) => ({
    id: row.id,
    no: row.number,
    fullname: row.fullname,
    tweet: row.tweet,
    liked: row.liked,
    values: row.values,
    blocked: row.blocked,
  }));

  const dataTopValue = topValue.map((row) => ({
    no: row.number,
    value: row.value,
  }));

  const dataTopTweet = topTweet.map((row) => ({
    total: row.total,
    user: row.user,
  }));

  const columnsTweet = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "5%",
    },
    {
      title: "Fullname",
      dataIndex: "fullname",
      key: "fullname",
      width: "15%",
    },
    {
      title: "Tweet",
      dataIndex: "tweet",
      key: "tweet",
    },
    {
      title: "Liked",
      dataIndex: "liked",
      key: "liked",
    },
    {
      title: "Values",
      dataIndex: "values",
      key: "values",
    },
    {
      title: "Action",
      key: "action",
      width: "15%",
      render: (_, record) => (
        <Button
          className="outline-0"
          onClick={() => handleBlocked(_.id, _.blocked)}
        >
          {record.blocked === false ? <StopOutlined /> : <UnlockOutlined />}
        </Button>
      ),
    },
  ];

  const columnsTopValue = [
    {
      title: "No",
      key: "no",
      dataIndex: "no",
    },
    {
      title: "Value",
      key: "value",
      dataIndex: "value",
    },
  ];

  const columnsTopTweet = [
    {
      title: "Total post",
      key: "total",
      dataIndex: "total",
    },
    {
      title: "User",
      key: "user",
      dataIndex: "user",
    },
  ];

  return (
    <div>
      <Navigation link="/tweet-values" title="Tweet Values" />
      <Title level={2}>Tweet Values</Title>
      <Row className="rowgap-vbox" gutter={[24, 0]}>
        <Col xs={24} sm={24} md={12} lg={6} xl={6} className="mb-24">
          <Card bordered={false} className="card-tweet">
            <div className="number">
              <Row align="middle" gutter={[24, 0]}>
                <Col xs={18}>
                  <span className="text-secondary">Total Posts</span>
                  <Title level={3}>{tweetValues.total_posts}</Title>
                </Col>
                <Col xs={6}>
                  {/* <div className="icon-box">{c.icon}</div> */}
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6} className="mb-24">
          <Card bordered={false} className="card-tweet">
            <div className="number">
              <Row align="middle" gutter={[24, 0]}>
                <Col xs={18}>
                  <span className="text-secondary">Total Post Today</span>
                  <Title level={3}>{tweetValues.total_post_today}</Title>
                </Col>
                <Col xs={6}>
                  {/* <div className="icon-box">{c.icon}</div> */}
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col
          span={18}
          style={{
            height: "60vh",
            position: "relative",
            marginBottom: "1%",
            padding: "1%",
            marginTop: "2.5rem",
          }}
        >
          <Title level={4} className="text-center">
            Traffic Posted Tweets
          </Title>
          <Form
            fields={[
              {
                name: ["date"],
                value: [
                  dayjs(startTime ? startTime : lineChart.currentstartdate),
                  dayjs(endTime ? endTime : lineChart.currentenddate),
                ],
              },
            ]}
          >
            <Row>
              <Col>
                <Form.Item name="date">
                  <RangePicker
                    showTime={{
                      format: "HH:mm",
                    }}
                    format="YYYY-MM-DD HH:mm"
                    onChange={onChange}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Button
                  className="ms-2"
                  type="primary"
                  onClick={getDataLineChart}
                >
                  Filter
                </Button>
              </Col>
            </Row>
          </Form>
          <Line data={dataLine} style={{ marginTop: "1rem" }} />
        </Col>
        <Col
          span={6}
          style={{
            height: "85vh",
            position: "relative",
            padding: "1%",
            marginTop: "3.5rem",
          }}
        >
          <Title level={4} className="text-center">
            Values Segments
          </Title>
          <Pie data={dataPie} />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Title level={4} className="text-center">
            Top 5 Values
          </Title>
          <Table
            className="me-2"
            dataSource={dataTopValue}
            columns={columnsTopValue}
            loading={isLoading}
            pagination={false}
          />
        </Col>
        <Col span={12}>
          <Title level={4} className="text-center">
            Top 5 Users
          </Title>
          <Table
            className=""
            dataSource={dataTopTweet}
            columns={columnsTopTweet}
            loading={isLoading}
            pagination={false}
          />
        </Col>
      </Row>
      <Title level={4} className="mt-3 text-center">
        Data Post Tweet Values
      </Title>
      <Table
        className="mt-2"
        dataSource={dataTweet}
        columns={columnsTweet}
        loading={isLoading}
        pagination={false}
      />
      <Pagination
        className="mt-3"
        current={current}
        total={totalData}
        onChange={(page, size) => {
          getData(size, page);
          setCurrent(page);
        }}
      />
      {contextHolder}
    </div>
  );
};

export default TweetValues;
