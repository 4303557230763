import { Button, Form, Input, message } from "antd";
import Title from "antd/es/typography/Title";
import axios from "axios";
import { convertToHTML } from "draft-convert";
import {
  ContentState,
  convertFromHTML,
  convertFromRaw,
  convertToRaw,
  EditorState,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navigation from "../../components/layout/Navigation";

const UpdateNews = () => {
  const navigate = useNavigate();
  const [banner, setBanner] = useState(null);
  const [dataNews, setDataNews] = useState([""]);
  const [title, setTitle] = useState("");
  const { id } = useParams();
  const token = localStorage.getItem("token");
  // const [editorState, setEditorState] = useState(() =>
  //   EditorState.createEmpty()
  // );
  const [convertedContent, setConvertedContent] = useState(null);

  const getDetail = () => {
    axios
      .get(`${process.env.REACT_APP_URL}cultureapp/news/detail/${id}`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        setDataNews(res.data);
      })
      .catch((err) => {
        alert(err);
      });
  };

  // const calcState = (value) => {
  //   return value
  //     ? EditorState.createWithContent(convertFromRaw(JSON.parse(value)))
  //     : EditorState.createEmpty();
  // };

  // const [editorState, setEditorState] = useState(calcState(dataNews.body));

  // useEffect(() => {
  //   setEditorState(calcState(dataNews.body));
  // }, []);


  const [editorState, setEditorState] = useState(() => {
    const blocksFromHTML = convertFromHTML(String(dataNews.body ?? ""));
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    return EditorState.createWithContent(contentState);
  });

  useEffect(() => {
    getDetail();
    const html = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(html);
  }, [editorState]);

  // const convertedEditorState = EditorState.createWithContent(
  //   ContentState.createFromBlockArray(convertFromHTML(String(dataNews.body)))
  // );

  const handlePic = (e) => {
    e.preventDefault();
    setBanner(e.target.files[0]);
  };

  const handleUpdate = () => {
    const PPData = new FormData();
    PPData.append("banner", banner ? banner : "");
    PPData.append("title", title ? title : dataNews?.title);
    PPData.append(
      "description",
      convertedContent !== "<p></p>" ? convertedContent : dataNews.body
    );
    axios
      .put(`${process.env.REACT_APP_URL}cultureapp/news/update/${id}`, PPData, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        navigate("/news");
        message.success("You have successfully updated!");
      })
      .catch((err) => {
        message.error("Error! Try again.");
      });
  };

  const toolbarOptions = {
    options: ["inline", "fontSize", "blockType", "list"],
    inline: {
      options: ["bold", "italic", "underline", "strikethrough"],
    },
    list: {
      options: ["unordered", "ordered"],
    },
    blockType: {
      options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
    },
  };

  return (
    <>
      <Navigation link="/news" title="News" title2="Update News" />
      <Title>{dataNews.title}</Title>
      <Form layout="vertical" className="row-col">
        <div>
          {banner ? (
            <img alt="" width={150} src={URL.createObjectURL(banner)} />
          ) : (
            <img alt="" width={150} src={dataNews.banner} />
          )}
        </div>
        <Form.Item label="Set Image:" name="banner" className="mt-3">
          <Input
            type="file"
            name="banner"
            className="w-95"
            onChange={handlePic}
          />
        </Form.Item>
        <p>Title:</p>
        <input
          type="text"
          name="title"
          className="w-95"
          placeholder="title"
          defaultValue={dataNews.title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <Form.Item label="Description:" name="description" className="mt-3">
          <Editor
            editorState={editorState}
            onEditorStateChange={setEditorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            toolbar={toolbarOptions}
          />
        </Form.Item>
      </Form>
      <Button type="primary w-95 btn-submit" onClick={handleUpdate}>
        Submit
      </Button>
    </>
  );
};

export default UpdateNews;
