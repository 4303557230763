import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Pagination,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import {
  InfoCircleOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Option } from "antd/es/mentions";
import Navigation from "../../components/layout/Navigation";
import axios from "axios";
import { AddButton } from "../../components/layout/AddButton";
import { useNavigate, useParams } from "react-router-dom";

const QuestionSurveyDetail = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [dataQuestionSurvey, setDataQuestionSurvey] = useState([""]);
  const [dataQuestionDetail, setDataQuestionDetail] = useState([""]);
  const [modal, contextHolder] = Modal.useModal();
  const [isLoading, setIsLoading] = useState(true);
  const [totalData, setTotalData] = useState(0);
  const [current, setCurrent] = useState(1);
  const { id } = useParams();
  const token = localStorage.getItem("token");

  const hideModal = () => {
    setOpen(false);
  };

  const getData = (pageSize, pageIndex) => {
    axios
      .get(
        `${
          process.env.REACT_APP_URL
        }cultureapp/survey/get_survey_questionnaire/${id}/${pageSize ?? 10}/${
          pageIndex ?? 1
        }`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        setDataQuestionSurvey(res.data.data);
        setTotalData(res.data.totaldata);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, [token, id]);

  const handleDelete = (idQuestion) => {
    modal.confirm({
      title: "Delete Confirmation",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure you want to delete this?",
      okText: "OK",
      cancelText: "Cancel",
      onOk: () => {
        axios
          .post(
            `${process.env.REACT_APP_URL}cultureapp/survey/delete_survey_questionnaire`,
            { id_survey: id, id_questionnaire: idQuestion },
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          )
          .then((res) => {
            window.location.reload(false);
            message.success("You have succesfully deleted this!");
          })
          .catch((err) => {
            alert(err);
          });
      },
    });
  };

  const fetchDataQuestion = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_URL}cultureapp/questionnaire/detail/${id}`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        setDataQuestionDetail(res.data);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const data = dataQuestionSurvey.map((row) => ({
    id: row.id,
    no: row.number,
    question: row.question,
  }));

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "5%",
    },
    {
      title: "Question",
      dataIndex: "question",
      key: "question",
    },
    {
      title: "Action",
      key: "action",
      width: "15%",
      render: (_, record) => (
        <Space size="middle">
          <Button
            className="outline-0"
            onClick={() => {
              setOpen(true);
              fetchDataQuestion(record.id);
            }}
          >
            <InfoCircleOutlined />
          </Button>
          <Button className="outline-0" onClick={() => handleDelete(record.id)}>
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Navigation
        link="/survey"
        title="Survey"
        title2="Question Survey Detail"
      />
      <div className="title">
        <Title level={2}>Question Survey Detail</Title>
        <AddButton
          children="questions"
          click={() => navigate(`/survey/add-question-survey-detail/${id}`)}
        />
      </div>
      <Table
        dataSource={data}
        columns={columns}
        loading={isLoading}
        pagination={false}
      />
      <Pagination
        className="mt-3"
        current={current}
        total={totalData}
        onChange={(page, size) => {
          getData(size, page);
          setCurrent(page);
        }}
      />

      {/* for modal */}
      <Modal
        title="Question Detail"
        open={open}
        onCancel={hideModal}
        footer={null}
      >
        <Form
          layout="vertical"
          className="row-col form-question"
          fields={[
            {
              name: ["question"],
              value: dataQuestionDetail.question,
            },
            {
              name: ["type"],
              value: dataQuestionDetail.type,
            },
          ]}
          disabled={true}
        >
          <Form.Item label="Question:" name="question">
            <Input type="text" name="question" className="w-95" />
          </Form.Item>
          <Form.Item label="Type:" name="type">
            <Select
              name="type"
              style={{ width: "95%" }}
              options={[
                {
                  value: "0",
                  label: "Multiple Choice",
                },
                {
                  value: "1",
                  label: "Rating (5 Options)",
                },
                {
                  value: "2",
                  label: "Rating (10 Options)",
                },
                {
                  value: "3",
                  label: "Essay",
                },
              ]}
            />
          </Form.Item>
          {dataQuestionDetail.type === "0" ? (
            <Form
              layout="vertical"
              className="row-col form-question"
              fields={[
                {
                  name: ["option_a"],
                  value: dataQuestionDetail.option.option_a,
                },
                {
                  name: ["option_b"],
                  value: dataQuestionDetail.option.option_b,
                },
                {
                  name: ["option_c"],
                  value: dataQuestionDetail.option.option_c,
                },
                {
                  name: ["option_d"],
                  value: dataQuestionDetail.option.option_d,
                },
              ]}
            >
              <Form.Item label="Option a:" name="option_a">
                <Input type="text" name="option_a" className="w-95" />
              </Form.Item>
              <Form.Item label="Option b:" name="option_b">
                <Input type="text" name="option_b" className="w-95" />
              </Form.Item>
              <Form.Item label="Option c:" name="option_c">
                <Input type="text" name="option_c" className="w-95" />
              </Form.Item>
              <Form.Item label="Option d:" name="option_d">
                <Input type="text" name="option_d" className="w-95" />
              </Form.Item>
            </Form>
          ) : dataQuestionDetail.type === "1" ? (
            <Form
              layout="vertical"
              className="row-col form-question"
              fields={[
                {
                  name: ["option_1"],
                  value: dataQuestionDetail.option.option_1,
                },
                {
                  name: ["option_2"],
                  value: dataQuestionDetail.option.option_2,
                },
                {
                  name: ["option_3"],
                  value: dataQuestionDetail.option.option_3,
                },
                {
                  name: ["option_4"],
                  value: dataQuestionDetail.option.option_4,
                },
                {
                  name: ["option_5"],
                  value: dataQuestionDetail.option.option_5,
                },
              ]}
            >
              <Form.Item label="1:" name="option_1">
                <Input type="text" name="option_1" className="w-95" />
              </Form.Item>
              <Form.Item label="2:" name="option_2">
                <Input type="text" name="option_2" className="w-95" />
              </Form.Item>
              <Form.Item label="3:" name="option_3">
                <Input type="text" name="option_3" className="w-95" />
              </Form.Item>
              <Form.Item label="4:" name="option_4">
                <Input type="text" name="option_4" className="w-95" />
              </Form.Item>
              <Form.Item label="5:" name="option_5">
                <Input type="text" name="option_5" className="w-95" />
              </Form.Item>
            </Form>
          ) : dataQuestionDetail.type === "2" ? (
            <Form
              layout="vertical"
              className="row-col form-question"
              fields={[
                {
                  name: ["option_1"],
                  value: dataQuestionDetail.option.option_1,
                },
                {
                  name: ["option_2"],
                  value: dataQuestionDetail.option.option_2,
                },
                {
                  name: ["option_3"],
                  value: dataQuestionDetail.option.option_3,
                },
                {
                  name: ["option_4"],
                  value: dataQuestionDetail.option.option_4,
                },
                {
                  name: ["option_5"],
                  value: dataQuestionDetail.option.option_5,
                },
                {
                  name: ["option_6"],
                  value: dataQuestionDetail.option.option_6,
                },
                {
                  name: ["option_7"],
                  value: dataQuestionDetail.option.option_7,
                },
                {
                  name: ["option_8"],
                  value: dataQuestionDetail.option.option_8,
                },
                {
                  name: ["option_9"],
                  value: dataQuestionDetail.option.option_9,
                },
                {
                  name: ["option_10"],
                  value: dataQuestionDetail.option.option_10,
                },
              ]}
            >
              <Row>
                <Col span={12}>
                  <Form.Item label="1:" name="option_1">
                    <Input type="text" name="option_1" className="w-95" />
                  </Form.Item>
                  <Form.Item label="2:" name="option_2">
                    <Input type="text" name="option_2" className="w-95" />
                  </Form.Item>
                  <Form.Item label="3:" name="option_3">
                    <Input name="option_3" className="w-95" />
                  </Form.Item>
                  <Form.Item label="4:" name="option_4">
                    <Input type="text" name="option_4" className="w-95" />
                  </Form.Item>
                  <Form.Item label="5:" name="option_5">
                    <Input type="text" name="option_5" className="w-95" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="6:" name="option_6">
                    <Input type="text" name="option_6" className="w-95" />
                  </Form.Item>
                  <Form.Item label="7:" name="option_7">
                    <Input type="text" name="option_7" className="w-95" />
                  </Form.Item>
                  <Form.Item label="8:" name="option_8">
                    <Input name="option_8" className="w-95" />
                  </Form.Item>
                  <Form.Item label="9:" name="option_9">
                    <Input type="text" name="option_9" className="w-95" />
                  </Form.Item>
                  <Form.Item label="10:" name="option_10">
                    <Input type="text" name="option_10" className="w-95" />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          ) : (
            ""
          )}
        </Form>
      </Modal>
    </>
  );
};

export default QuestionSurveyDetail;
