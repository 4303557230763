import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Pagination,
  Row,
  Table,
} from "antd";
import Title from "antd/es/typography/Title";
import axios from "axios";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useEffect } from "react";
import { Line } from "react-chartjs-2";
import Navigation from "../../components/layout/Navigation";

const Dashboard = () => {
  const { RangePicker } = DatePicker;
  const [dashboard, setDashboard] = useState("");
  const [lineChart, setLineChart] = useState([""]);
  const [dataLeaderboard, setDataLeaderBoard] = useState([""]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalData, setTotalData] = useState(0);
  const [current, setCurrent] = useState(1);
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [defEndTime, setDefEndTime] = useState();
  const [defStartTime, setDefStartTime] = useState();
  const token = localStorage.getItem("token");

  const getData = (pageSize, pageIndex) => {
    axios
      .get(
        `${process.env.REACT_APP_URL}cultureapp/dashboard/top10leaderboard/${
          pageSize ?? 10
        }/${pageIndex ?? 1}`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        setDataLeaderBoard(res.data.data);
        setTotalData(res.data.totaldata);
        setIsLoading(false);
      });
  };

  const getTimestamp30DaysAgo = () => {
    var d = new Date();
    var init = d.getMonth() == 0 ? 12 : 1;
    d.setMonth(d.getMonth() - init);
    var unixTimeStamp = Math.floor(d.getTime() / 1000);
    setDefStartTime(unixTimeStamp);
  };

  const getTimestampNow = () => {
    var d = new Date();
    var unixTimeStamp = Math.floor(d.getTime() / 1000);
    setDefEndTime(unixTimeStamp);
  };

  useEffect(() => {
    getTimestamp30DaysAgo();
    getTimestampNow();
    axios
      .get(`${process.env.REACT_APP_URL}cultureapp/dashboard/box_info`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        setDashboard(res.data);
      });
    // axios
    //   .get(
    //     `${process.env.REACT_APP_URL}cultureapp/dashboard/line_chart/${defStartTime}/${defEndTime}`,
    //     {
    //       headers: { Authorization: "Bearer " + token },
    //     }
    //   )
    //   .then((res) => {
    //     setLineChart(res.data);
    //   });
    getData();
  }, [token, defEndTime, defStartTime]);

  const onChange = (dateString) => {
    setStartTime(dateString[0]);
    setEndTime(dateString[1]);
  };

  const convertedStartTime = Math.floor(new Date(startTime).getTime() / 1000);
  const convertedEndTime = Math.floor(new Date(endTime).getTime() / 1000);

  const getDataLineChart = () => {
    axios
      .get(
        `${process.env.REACT_APP_URL}cultureapp/dashboard/line_chart/${convertedStartTime}/${convertedEndTime}`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        setLineChart(res.data);
      });
  };

  const dataLine = {
    labels: lineChart.label,
    datasets: [
      {
        label: "First dataset",
        data: lineChart.data,
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
    options: {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    },
  };

  const dataDashboard = dataLeaderboard.map((row) => ({
    id: row.id,
    no: row.number,
    fullname: row.fullname,
    unit: row.unit,
    point: row.total,
  }));

  const columnsDashboard = [
    {
      title: "No",
      key: "no",
      dataIndex: "no",
      width: "5%",
    },
    {
      title: "Fullname",
      key: "fullname",
      dataIndex: "fullname",
    },
    {
      title: "Unit",
      key: "unit",
      dataIndex: "unit",
    },
    {
      title: "Point",
      key: "point",
      dataIndex: "point",
    },
  ];

  return (
    <>
      <Navigation />
      <Title level={2}>Dashboard</Title>
      <Row className="rowgap-vbox" gutter={[24, 0]}>
        <Col xs={24} sm={24} md={12} lg={6} xl={6} className="mb-24">
          <Card bordered={false} className="card-tweet">
            <div className="number">
              <Row align="middle" gutter={[24, 0]}>
                <Col xs={18}>
                  <span className="text-secondary">Total Users</span>
                  <Title level={3}>{dashboard.total_users}</Title>
                </Col>
                <Col xs={6}>
                  {/* <div className="icon-box">{c.icon}</div> */}
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6} className="mb-24">
          <Card bordered={false} className="card-tweet">
            <div className="number">
              <Row align="middle" gutter={[24, 0]}>
                <Col xs={18}>
                  <span className="text-secondary">Total Login Today</span>
                  <Title level={3}>{dashboard.total_login_today}</Title>
                </Col>
                <Col xs={6}>
                  {/* <div className="icon-box">{c.icon}</div> */}
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
      <Col
        style={{
          // height:"30%",
          position: "relative",
          marginBottom: "1%",
          padding: "1%",
          marginTop: "2.5rem",
        }}
      >
        {/* <Title level={4} className="text-center">
          Traffic Login
        </Title> */}
        {/* <Form
          fields={[
            {
              name: ["date"],
              value: [
                dayjs(startTime ? startTime : lineChart.currentstartdate),
                dayjs(endTime ? endTime : lineChart.currentenddate),
              ],
            },
          ]}
        >
          <Row>
            <Col>
              <Form.Item name="date">
                <RangePicker
                  showTime={{
                    format: "HH:mm",
                  }}
                  format="YYYY-MM-DD HH:mm"
                  onChange={onChange}
                />
              </Form.Item>
            </Col>
            <Col>
              <Button
                className="ms-2"
                type="primary"
                onClick={getDataLineChart}
              >
                Filter
              </Button>
            </Col>
          </Row>
        </Form> */}
        {/* <Line data={dataLine} style={{ marginTop: "0.5rem", height: "20%" }} /> */}
      </Col>
      <Title level={4} className="mt-3 text-center">
        Top 10 Leaderboard
      </Title>
      <Table
        className="mt-2"
        dataSource={dataDashboard}
        columns={columnsDashboard}
        loading={isLoading}
        pagination={false}
      />
      <Pagination
        className="mt-3"
        current={current}
        total={totalData}
        onChange={(page, size) => {
          getData(size, page);
          setCurrent(page);
        }}
      />
    </>
  );
};

export default Dashboard;
