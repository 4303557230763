import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Pagination,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Option } from "antd/es/mentions";
import Navigation from "../../components/layout/Navigation";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Search from "antd/es/input/Search";

const AddQuestionSurveyDetail = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [dataQuestion, setDataQuestion] = useState([""]);
  const [dataQuestionDetail, setDataQuestionDetail] = useState([""]);
  const [selectedRowKeys, setSelectedRowKeys] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [totalData, setTotalData] = useState(0);
  const [current, setCurrent] = useState(1);
  const { id } = useParams();
  const token = localStorage.getItem("token");

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hideModal = () => {
    setOpen(false);
  };

  const selectedRowKeysString = selectedRowKeys.toString().replace(/,/g, ";");

  const addQuestion = () => {
    axios
      .post(
        `${process.env.REACT_APP_URL}cultureapp/survey/set_questionnaire_survey`,
        { id_survey: id, list_id_questionnaire: selectedRowKeysString },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        navigate(`/survey/question-survey-detail/${id}`);
        message.success("You have succesfully submitted!");
      })
      .catch((err) => {
        message.error("Error! Try again.");
      });
  };

  const getData = (pageSize, pageIndex, searchIndex) => {
    axios
      .get(
        `${
          process.env.REACT_APP_URL
        }cultureapp/survey/list_add_questionnaire_survey/${id}/${
          pageSize ?? 10
        }/${pageIndex ?? 1}`,
        {
          headers: { Authorization: "Bearer " + token },
          params: { search: searchIndex },
        }
      )
      .then((res) => {
        setDataQuestion(res.data.data);
        setTotalData(res.data.totaldata);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const fetchDataQuestion = (id) => {
    axios
      .get(`${process.env.REACT_APP_URL}cultureapp/questionnaire/detail/${id}`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        setDataQuestionDetail(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const data = dataQuestion.map((row) => ({
    no: row.number,
    key: row.id,
    question: row.question,
  }));

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "5%",
    },
    {
      title: "Question",
      dataIndex: "question",
      key: "question",
    },
    {
      title: "Action",
      key: "action",
      width: "15%",
      render: (_, record) => (
        <Space size="middle">
          <Button
            className="outline-0"
            onClick={() => {
              setOpen(true);
              fetchDataQuestion(record.key);
            }}
          >
            <InfoCircleOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Navigation
        link="/survey"
        title="Survey"
        link2={`/survey/question-survey-detail/${id}`}
        title2="Question Survey Detail"
        title3="Add Questions in Survey"
      />
      <Title level={2}>Add Questions in Survey</Title>
      <div className="title">
        <Search
          placeholder="Search Name"
          onSearch={(value) => {
            getData(10, 1, value);
          }}
          style={{ width: "60%" }}
          allowClear
        />
        <Button type="primary btn-submit" onClick={addQuestion}>
          Submit
        </Button>
      </div>
      <Table
        className="mt-2"
        rowSelection={rowSelection}
        dataSource={data}
        columns={columns}
        loading={isLoading}
        pagination={false}
      />
      <Pagination
        className="mt-3"
        current={current}
        total={totalData}
        onChange={(page, size) => {
          getData(size, page);
          setCurrent(page);
        }}
      />

      {/* for modal */}
      <Modal
        title="Question Detail"
        open={open}
        onCancel={hideModal}
        footer={null}
      >
        <Form
          layout="vertical"
          className="row-col form-question"
          fields={[
            {
              name: ["question"],
              value: dataQuestionDetail.question,
            },
            {
              name: ["type"],
              value: dataQuestionDetail.type,
            },
          ]}
          disabled={true}
        >
          <Form.Item label="Question:" name="question">
            <Input type="text" name="question" className="w-95" />
          </Form.Item>
          <Form.Item label="Type:" name="type">
            <Select
              name="type"
              style={{ width: "95%" }}
              options={[
                {
                  value: "0",
                  label: "Multiple Choice",
                },
                {
                  value: "1",
                  label: "Rating (5 Options)",
                },
                {
                  value: "2",
                  label: "Rating (10 Options)",
                },
                {
                  value: "3",
                  label: "Essay",
                },
              ]}
            />
          </Form.Item>
          {dataQuestionDetail.type === "0" ? (
            <Form
              layout="vertical"
              className="row-col form-question"
              fields={[
                {
                  name: ["option_a"],
                  value: dataQuestionDetail.option.option_a,
                },
                {
                  name: ["option_b"],
                  value: dataQuestionDetail.option.option_b,
                },
                {
                  name: ["option_c"],
                  value: dataQuestionDetail.option.option_c,
                },
                {
                  name: ["option_d"],
                  value: dataQuestionDetail.option.option_d,
                },
              ]}
            >
              <Form.Item label="Option a:" name="option_a">
                <Input type="text" name="option_a" className="w-95" />
              </Form.Item>
              <Form.Item label="Option b:" name="option_b">
                <Input type="text" name="option_b" className="w-95" />
              </Form.Item>
              <Form.Item label="Option c:" name="option_c">
                <Input type="text" name="option_c" className="w-95" />
              </Form.Item>
              <Form.Item label="Option d:" name="option_d">
                <Input type="text" name="option_d" className="w-95" />
              </Form.Item>
            </Form>
          ) : dataQuestionDetail.type === "1" ? (
            <Form
              layout="vertical"
              className="row-col form-question"
              fields={[
                {
                  name: ["option_1"],
                  value: dataQuestionDetail.option.option_1,
                },
                {
                  name: ["option_2"],
                  value: dataQuestionDetail.option.option_2,
                },
                {
                  name: ["option_3"],
                  value: dataQuestionDetail.option.option_3,
                },
                {
                  name: ["option_4"],
                  value: dataQuestionDetail.option.option_4,
                },
                {
                  name: ["option_5"],
                  value: dataQuestionDetail.option.option_5,
                },
              ]}
            >
              <Form.Item label="1:" name="option_1">
                <Input type="text" name="option_1" className="w-95" />
              </Form.Item>
              <Form.Item label="2:" name="option_2">
                <Input type="text" name="option_2" className="w-95" />
              </Form.Item>
              <Form.Item label="3:" name="option_3">
                <Input type="text" name="option_3" className="w-95" />
              </Form.Item>
              <Form.Item label="4:" name="option_4">
                <Input type="text" name="option_4" className="w-95" />
              </Form.Item>
              <Form.Item label="5:" name="option_5">
                <Input type="text" name="option_5" className="w-95" />
              </Form.Item>
            </Form>
          ) : dataQuestionDetail.type === "2" ? (
            <Form
              layout="vertical"
              className="row-col form-question"
              fields={[
                {
                  name: ["option_1"],
                  value: dataQuestionDetail.option.option_1,
                },
                {
                  name: ["option_2"],
                  value: dataQuestionDetail.option.option_2,
                },
                {
                  name: ["option_3"],
                  value: dataQuestionDetail.option.option_3,
                },
                {
                  name: ["option_4"],
                  value: dataQuestionDetail.option.option_4,
                },
                {
                  name: ["option_5"],
                  value: dataQuestionDetail.option.option_5,
                },
                {
                  name: ["option_6"],
                  value: dataQuestionDetail.option.option_6,
                },
                {
                  name: ["option_7"],
                  value: dataQuestionDetail.option.option_7,
                },
                {
                  name: ["option_8"],
                  value: dataQuestionDetail.option.option_8,
                },
                {
                  name: ["option_9"],
                  value: dataQuestionDetail.option.option_9,
                },
                {
                  name: ["option_10"],
                  value: dataQuestionDetail.option.option_10,
                },
              ]}
            >
              <Row>
                <Col span={12}>
                  <Form.Item label="1:" name="option_1">
                    <Input type="text" name="option_1" className="w-95" />
                  </Form.Item>
                  <Form.Item label="2:" name="option_2">
                    <Input type="text" name="option_2" className="w-95" />
                  </Form.Item>
                  <Form.Item label="3:" name="option_3">
                    <Input name="option_3" className="w-95" />
                  </Form.Item>
                  <Form.Item label="4:" name="option_4">
                    <Input type="text" name="option_4" className="w-95" />
                  </Form.Item>
                  <Form.Item label="5:" name="option_5">
                    <Input type="text" name="option_5" className="w-95" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="6:" name="option_6">
                    <Input type="text" name="option_6" className="w-95" />
                  </Form.Item>
                  <Form.Item label="7:" name="option_7">
                    <Input type="text" name="option_7" className="w-95" />
                  </Form.Item>
                  <Form.Item label="8:" name="option_8">
                    <Input name="option_8" className="w-95" />
                  </Form.Item>
                  <Form.Item label="9:" name="option_9">
                    <Input type="text" name="option_9" className="w-95" />
                  </Form.Item>
                  <Form.Item label="10:" name="option_10">
                    <Input type="text" name="option_10" className="w-95" />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          ) : (
            ""
          )}
        </Form>
      </Modal>
    </>
  );
};

export default AddQuestionSurveyDetail;
