import { Button, Space, Switch, Table, message, Pagination } from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import { ModalDelete } from "../../components/layout/ModalDelete";
import { InfoCircleOutlined, QuestionCircleOutlined, EditOutlined } from "@ant-design/icons";
import { AddButton } from "../../components/layout/AddButton";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Navigation from "../../components/layout/Navigation";
import Search from "antd/es/input/Search";

const Quiz = () => {
  const navigate = useNavigate();
  const [dataQuiz, setDataQuiz] = useState([""]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalData, setTotalData] = useState(0);
  const [current, setCurrent] = useState(1);
  const token = localStorage.getItem("token");

  const getData = (pageSize, pageIndex, searchIndex) => {
    axios
      .get(
        `${process.env.REACT_APP_URL}cultureapp/quiz/list/${pageSize ?? 10}/${
          pageIndex ?? 1
        }`,
        {
          headers: { Authorization: "Bearer " + token },
          params: { search: searchIndex },
        }
      )
      .then((res) => {
        setDataQuiz(res.data.data);
        setTotalData(res.data.totaldata);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const onChangeTest = (checked, id) => {
    axios
      .post(
        `${process.env.REACT_APP_URL}cultureapp/quiz/set_active_quiz`,
        { id_quiz: id },
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        if (checked) {
          message.success("This quiz is active!");
        }
        window.location.reload(false);
      });
  };

  const data = dataQuiz.map((row) => ({
    id: row.id,
    no: row.number,
    title: row.title,
    from: row.timestart,
    until: row.timeend,
    duration: row.duration,
    is_active: row.is_active,
  }));

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "5%",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "From",
      dataIndex: "from",
      key: "from",
    },
    {
      title: "Until",
      dataIndex: "until",
      key: "until",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
    },
    {
      title: "Active",
      dataIndex: "is_active",
      key: "is_active",
      render: (_, record) => (
        <Switch
          checked={_}
          onChange={(e) => {
            onChangeTest(e, record.id);
          }}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "15%",
      render: (_, record) => (
        <Space size="middle">
          <Button
            className="outline-0"
            onClick={() => navigate(`/quiz/update-quiz/${record.id}`)}
          >
            <EditOutlined />
          </Button>
          <ModalDelete url="quiz" id={record.id}></ModalDelete>
          <Button
            className="outline-0"
            onClick={() => navigate(`/quiz/question-quiz-detail/${record.id}`)}
          >
            <QuestionCircleOutlined />
          </Button>
          <Button
            className="outline-0"
            onClick={() => navigate(`/quiz/detail-quiz/${record.id}`)}
          >
            <InfoCircleOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Navigation link="/quiz" title="Quiz" />
      <Title level={2}>Quiz</Title>
      <div className="title">
        <Search
          placeholder="Search Name"
          onSearch={(value) => {
            getData(10, 1, value);
          }}
          style={{ width: "60%" }}
          allowClear
        />
        <div>
          <Button className="me-2" type="primary" onClick={() => navigate("/quiz/question-bank-quiz")}>
            Question Bank
          </Button>
          <AddButton children="quiz" click={() => navigate("/quiz/add-quiz")} />
        </div>
      </div>
      <Table
        className="mt-2"
        dataSource={data}
        columns={columns}
        loading={isLoading}
        pagination={false}
      />
      <Pagination
        className="mt-3"
        current={current}
        total={totalData}
        onChange={(page, size) => {
          getData(size, page);
          setCurrent(page);
        }}
      />
    </>
  );
};

export default Quiz;
