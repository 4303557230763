import { Button, Space, Switch, Table, message, Pagination } from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import { ModalDelete } from "../../components/layout/ModalDelete";
import {
  InfoCircleOutlined,
  QuestionCircleOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { AddButton } from "../../components/layout/AddButton";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Navigation from "../../components/layout/Navigation";
import Search from "antd/es/input/Search";

const DetailQuiz = () => {
  const [dataQuiz, setDataQuiz] = useState([""]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalData, setTotalData] = useState(0);
  const [current, setCurrent] = useState(1);
  const { id } = useParams();
  const token = localStorage.getItem("token");

  const getData = (pageSize, pageIndex, searchIndex) => {
    axios
      .get(
        `${process.env.REACT_APP_URL}cultureapp/quiz/get_finished_user/${id}/${pageSize ?? 10}/${
          pageIndex ?? 1
        }`,
        {
          headers: { Authorization: "Bearer " + token },
          params: { search: searchIndex },
        }
      )
      .then((res) => {
        setDataQuiz(res.data.data);
        console.log(res.data.data);
        setTotalData(res.data.totaldata);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const onChangeTest = (checked, id) => {
    axios
      .post(
        `${process.env.REACT_APP_URL}cultureapp/quiz/set_active_quiz`,
        { id_quiz: id },
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        if (checked) {
          message.success("This quiz is active!");
        }
        window.location.reload(false);
      });
  };

  const data = dataQuiz.map((row) => ({
    id: row.id,
    no: row.number,
    fullname: row.fullname,
    unit: row.unit,
    score: row.score,
    date: row.timefinished
  }));

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "5%",
    },
    {
      title: "Fullname",
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
    },
    // {
    //   title: "Jabatan",
    //   dataIndex: "jabatan",
    //   key: "jabatan",
    // },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    }
  ];

  return (
    <>
      <Navigation link="/quiz" title="Quiz" title2="Quiz Detail" />
      <Title level={2}>Quiz Detail</Title>
      <div className="title">
        <Search
          placeholder="Search Name"
          onSearch={(value) => {
            getData(10, 1, value);
          }}
          style={{ width: "60%" }}
          allowClear
        />
      </div>
      <Table
        className="mt-2"
        dataSource={data}
        columns={columns}
        loading={isLoading}
        pagination={false}
      />
      <Pagination
        className="mt-3"
        current={current}
        total={totalData}
        onChange={(page, size) => {
          getData(size, page);
          setCurrent(page);
        }}
      />
    </>
  );
};

export default DetailQuiz;
