import { Button, Col, Form, Input, message, Row, Select } from "antd";
import Title from "antd/es/typography/Title";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navigation from "../../components/layout/Navigation";

const AddQuestionBankSurvey = () => {
  const navigate = useNavigate();
  const [type, setType] = useState("");
  const [question, setQuestion] = useState("");
  const token = localStorage.getItem("token");
  const [form, setForm] = useState({
    option_a: "",
    option_b: "",
    option_c: "",
    option_d: "",
  });
  const [form1, setForm1] = useState({
    option_1: "",
    option_2: "",
    option_3: "",
    option_4: "",
    option_5: "",
  });
  const [form2, setForm2] = useState({
    option_1: "",
    option_2: "",
    option_3: "",
    option_4: "",
    option_5: "",
    option_6: "",
    option_7: "",
    option_8: "",
    option_9: "",
    option_10: "",
  });

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  const handleChange1 = (e) => {
    setForm1({
      ...form1,
      [e.target.name]: e.target.value,
    });
  };
  const handleChange2 = (e) => [
    setForm2({
      ...form2,
      [e.target.name]: e.target.value,
    }),
  ];

  const handleAdd = () => {
    axios
      .post(
        `${process.env.REACT_APP_URL}cultureapp/questionnaire/add`,
        {
          question: question,
          type: type,
          option:
            type === "0"
              ? form
              : type === "1"
              ? form1
              : type === "2"
              ? form2
              : "",
        },
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        navigate("/survey/question-bank-survey");
        message.success("You have successfully submitted!");
      })
      .catch((err) => {
        message.error("Error! Try again.");
      });
  };

  return (
    <div className="form-question">
      <Navigation
        link="/survey"
        title="Survey"
        link2="/survey/question-bank-survey"
        title2="Question Bank Survey"
        link3="/survey/add-question-bank-survey"
        title3="Add Question Bank Survey"
      />
      <Title>Add Question Bank Survey</Title>
      <Form layout="vertical" className="row-col form-question">
        <Form.Item label="Question:" name="question">
          <Input
            type="text"
            name="question"
            className="w-95"
            placeholder="question"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Type:" name="type">
          <Select
            name="type"
            style={{ width: "95%" }}
            placeholder="type"
            value={type}
            onChange={(value) => setType(value)}
            options={[
              {
                value: "0",
                label: "Multiple Choice",
              },
              {
                value: "1",
                label: "Rating (5 Options)",
              },
              {
                value: "2",
                label: "Rating (10 Options)",
              },
              {
                value: "3",
                label: "Essay",
              },
            ]}
          />
        </Form.Item>
        {type === "0" ? (
          <>
            <Form.Item label="Option a:" name="option_a">
              <Input
                type="text"
                name="option_a"
                className="w-95"
                placeholder="option a"
                value={form.option_a}
                onChange={handleChange}
              />
            </Form.Item>
            <Form.Item label="Option b:" name="option_b">
              <Input
                type="text"
                name="option_b"
                className="w-95"
                placeholder="option b"
                value={form.option_b}
                onChange={handleChange}
              />
            </Form.Item>
            <Form.Item label="Option c:" name="option_c">
              <Input
                name="option_c"
                className="w-95"
                placeholder="option c"
                value={form.option_c}
                onChange={handleChange}
              />
            </Form.Item>
            <Form.Item label="Option d:" name="option_d">
              <Input
                type="text"
                name="option_d"
                className="w-95"
                placeholder="option d"
                value={form.option_d}
                onChange={handleChange}
              />
            </Form.Item>
          </>
        ) : type === "1" ? (
          <>
            <Form.Item label="1:" name="option_1">
              <Input
                type="text"
                name="option_1"
                className="w-95"
                placeholder="option 1"
                value={form1.option_1}
                onChange={handleChange1}
              />
            </Form.Item>
            <Form.Item label="2:" name="option_2">
              <Input
                type="text"
                name="option_2"
                className="w-95"
                placeholder="option 2"
                value={form1.option_2}
                onChange={handleChange1}
              />
            </Form.Item>
            <Form.Item label="3:" name="option_3">
              <Input
                name="option_3"
                className="w-95"
                placeholder="option 3"
                value={form1.option_3}
                onChange={handleChange1}
              />
            </Form.Item>
            <Form.Item label="4:" name="option_4">
              <Input
                type="text"
                name="option_4"
                className="w-95"
                placeholder="option 4"
                value={form1.option_4}
                onChange={handleChange1}
              />
            </Form.Item>
            <Form.Item label="5:" name="option_5">
              <Input
                type="text"
                name="option_5"
                className="w-95"
                placeholder="option 5"
                value={form1.option_5}
                onChange={handleChange1}
              />
            </Form.Item>
          </>
        ) : type === "2" ? (
          <Row>
            <Col span={12}>
              <Form.Item label="1:" name="option_1">
                <Input
                  type="text"
                  name="option_1"
                  className="w-95"
                  placeholder="option 1"
                  value={form2.option_1}
                  onChange={handleChange2}
                />
              </Form.Item>
              <Form.Item label="2:" name="option_2">
                <Input
                  type="text"
                  name="option_2"
                  className="w-95"
                  placeholder="option 2"
                  value={form2.option_2}
                  onChange={handleChange2}
                />
              </Form.Item>
              <Form.Item label="3:" name="option_3">
                <Input
                  name="option_3"
                  className="w-95"
                  placeholder="option 3"
                  value={form2.option_3}
                  onChange={handleChange2}
                />
              </Form.Item>
              <Form.Item label="4:" name="option_4">
                <Input
                  type="text"
                  name="option_4"
                  className="w-95"
                  placeholder="option 4"
                  value={form2.option_4}
                  onChange={handleChange2}
                />
              </Form.Item>
              <Form.Item label="5:" name="option_5">
                <Input
                  type="text"
                  name="option_5"
                  className="w-95"
                  placeholder="option 5"
                  value={form2.option_5}
                  onChange={handleChange2}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="6:" name="option_6">
                <Input
                  type="text"
                  name="option_6"
                  className="w-95"
                  placeholder="option 6"
                  value={form2.option_6}
                  onChange={handleChange2}
                />
              </Form.Item>
              <Form.Item label="7:" name="option_7">
                <Input
                  type="text"
                  name="option_7"
                  className="w-95"
                  placeholder="option 7"
                  value={form2.option_7}
                  onChange={handleChange2}
                />
              </Form.Item>
              <Form.Item label="8:" name="option_8">
                <Input
                  name="option_8"
                  className="w-95"
                  placeholder="option 8"
                  value={form2.option_8}
                  onChange={handleChange2}
                />
              </Form.Item>
              <Form.Item label="9:" name="option_9">
                <Input
                  type="text"
                  name="option_9"
                  className="w-95"
                  placeholder="option 9"
                  value={form2.option_9}
                  onChange={handleChange2}
                />
              </Form.Item>
              <Form.Item label="10:" name="option_10">
                <Input
                  type="text"
                  name="option_10"
                  className="w-95"
                  placeholder="option 10"
                  value={form2.option_10}
                  onChange={handleChange2}
                />
              </Form.Item>
            </Col>
          </Row>
        ) : (
          ""
        )}
      </Form>
      <Button type="primary btn-submit" onClick={handleAdd}>
        Submit
      </Button>
    </div>
  );
};

export default AddQuestionBankSurvey;
