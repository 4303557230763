import { Button, Col, Form, Input, message, Row } from "antd";
import Title from "antd/es/typography/Title";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navigation from "../../components/layout/Navigation";

const UpdateUser = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const { id } = useParams();
  const [dataUser, setDataUser] = useState("");

  const [form, setForm] = useState({
    email: "",
    username: "",
    password: "",
    fullname: "",
    telp: "",
    unit: "",
  });

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_URL}cultureapp/usermanagement/detail/${id}`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        setDataUser(res.data);
      })
      .catch((err) => {
        alert(err);
      });
  }, [id, token]);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdate = () => {
    axios
      .put(
        `${process.env.REACT_APP_URL}cultureapp/usermanagement/update/${id}`,
        {
          fullname: form.fullname ? form.fullname : dataUser?.fullname,
          username: form.username ? form.username : dataUser?.username,
          email: form.email ? form.email : dataUser?.email,
          telp: form.telp ? form.telp : dataUser?.telp,
          unit: form.unit ? form.unit : dataUser?.unit,
          password: form.password ? form.password : dataUser?.password,
        },
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        navigate("/user-management");
        message.success("You have successfully updated!");
      })
      .catch((err) => {
        message.error("Error! Try again.");
      });
  };

  return (
    <>
      <Navigation
        link="/user-management"
        title="User Management"
        title2="Update User"
      />
      <Title>Update User</Title>
      <Form layout="vertical">
        <Row>
          <Col span={12}>
            <p>Fullname:</p>
            <input
              type="text"
              name="fullname"
              className="w-95"
              placeholder="fullname"
              defaultValue={dataUser.fullname}
              onChange={handleChange}
            />
            <p>Username:</p>
            <input
              type="text"
              name="username"
              className="w-95"
              placeholder="username"
              defaultValue={dataUser.username}
              onChange={handleChange}
            />
            <p>Email:</p>
            <input
              type="text"
              name="email"
              className="w-95"
              placeholder="email"
              defaultValue={dataUser.email}
              onChange={handleChange}
            />
          </Col>
          <Col span={12}>
            <p>Telp:</p>
            <input
              type="number"
              name="telp"
              className="w-95"
              placeholder="telp"
              defaultValue={dataUser.telp}
              onChange={handleChange}
            />
            <p>Unit:</p>
            <input
              type="text"
              name="unit"
              className="w-95"
              placeholder="unit"
              defaultValue={dataUser.unit}
              onChange={handleChange}
            />
            <p>Password:</p>
            <input
              type="text"
              name="password"
              className="w-95"
              placeholder="password"
              defaultValue={dataUser.password}
              onChange={handleChange}
            />
          </Col>
        </Row>
      </Form>
      <Button type="primary w-25 mt-2 btn-submit" onClick={handleUpdate}>
        Submit
      </Button>
    </>
  );
};

export default UpdateUser;
