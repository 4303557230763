import { Button, Col, Form, Input, message, Row, Select } from "antd";
import Title from "antd/es/typography/Title";
import axios from "axios";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navigation from "../../components/layout/Navigation";

const UpdateQuestionSurvey = () => {
  const navigate = useNavigate();
  const [type, setType] = useState("");
  const [idOption, setIdOption] = useState("");
  const [question, setQuestion] = useState("");
  const [dataQuestionSurvey, setDataQuestionSurvey] = useState("");
  const token = localStorage.getItem("token");
  const { id } = useParams();
  const [form, setForm] = useState({
    option_a: "",
    option_b: "",
    option_c: "",
    option_d: "",
  });
  const [form1, setForm1] = useState({
    option_1: "",
    option_2: "",
    option_3: "",
    option_4: "",
    option_5: "",
  });
  const [form2, setForm2] = useState({
    option_1: "",
    option_2: "",
    option_3: "",
    option_4: "",
    option_5: "",
    option_6: "",
    option_7: "",
    option_8: "",
    option_9: "",
    option_10: "",
  });

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  const handleChange1 = (e) => {
    setForm1({
      ...form1,
      [e.target.name]: e.target.value,
    });
  };
  const handleChange2 = (e) => [
    setForm2({
      ...form2,
      [e.target.name]: e.target.value,
    }),
  ];

  const getDetail = () => {
    axios
      .get(
        `${process.env.REACT_APP_URL}cultureapp/questionnaire/detail/${id}`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        setDataQuestionSurvey(res.data);
        setType(res.data.type);
        setIdOption(res.data.option?.id);
      })
      .catch((err) => {
        alert(err);
      });
  };

  useEffect(() => {
    getDetail();
  }, []);

  const dataMultipleChoice = {
    id: idOption,
    option_a: form.option_a
      ? form.option_a
      : dataQuestionSurvey.option?.option_a,
    option_b: form.option_b
      ? form.option_b
      : dataQuestionSurvey.option?.option_b,
    option_c: form.option_c
      ? form.option_c
      : dataQuestionSurvey.option?.option_c,
    option_d: form.option_d
      ? form.option_d
      : dataQuestionSurvey.option?.option_d,
  };

  const dataRating5 = {
    id: idOption,
    option_1: form1.option_1
      ? form1.option_1
      : dataQuestionSurvey.option?.option_1,
    option_2: form1.option_2
      ? form1.option_2
      : dataQuestionSurvey.option?.option_2,
    option_3: form1.option_3
      ? form1.option_3
      : dataQuestionSurvey.option?.option_3,
    option_4: form1.option_4
      ? form1.option_4
      : dataQuestionSurvey.option?.option_4,
    option_5: form1.option_5
      ? form1.option_5
      : dataQuestionSurvey.option?.option_5,
  };

  const dataRating10 = {
    id: idOption,
    option_1: form1.option_1
      ? form1.option_1
      : dataQuestionSurvey.option?.option_1,
    option_2: form1.option_2
      ? form1.option_2
      : dataQuestionSurvey.option?.option_2,
    option_3: form1.option_3
      ? form1.option_3
      : dataQuestionSurvey.option?.option_3,
    option_4: form1.option_4
      ? form1.option_4
      : dataQuestionSurvey.option?.option_4,
    option_5: form1.option_5
      ? form1.option_5
      : dataQuestionSurvey.option?.option_5,
    option_6: form2.option_6
      ? form2.option_6
      : dataQuestionSurvey.option?.option_6,
    option_7: form2.option_7
      ? form2.option_7
      : dataQuestionSurvey.option?.option_7,
    option_8: form2.option_8
      ? form2.option_8
      : dataQuestionSurvey.option?.option_8,
    option_9: form2.option_9
      ? form2.option_9
      : dataQuestionSurvey.option?.option_9,
    option_10: form2.option_10
      ? form2.option_10
      : dataQuestionSurvey.option?.option_10,
  };

  const handleUpdate = () => {
    axios
      .put(
        `${process.env.REACT_APP_URL}cultureapp/questionnaire/update`,
        {
          id: id,
          question: question ? question : dataQuestionSurvey.question,
          type: type ? type : dataQuestionSurvey.type,
          option:
            type === "0"
              ? dataMultipleChoice
              : type === "1"
              ? dataRating5
              : type === "2"
              ? dataRating10
              : "",
        },
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        navigate("/question-bank-survey");
        message.success("You have successfully submitted!");
      })
      .catch((err) => {
        message.error("Error! Try again.");
      });
  };

  return (
    <div className="form-question">
      <Navigation
        link="/survey"
        title="Survey"
        link2="/survey/question-bank-survey"
        title2="Question Bank Survey"
        title3="Update Question Bank Survey"
      />
      <Title>Update Question Bank Survey</Title>
      <Form
        layout="vertical"
        className="row-col form-question"
        fields={[
          {
            name: ["type"],
            value: type ? type : dataQuestionSurvey.type,
          },
        ]}
      >
        <p>Question:</p>
        <input
          type="text"
          name="question"
          className="w-95"
          placeholder="question"
          defaultValue={dataQuestionSurvey.question}
          onChange={(e) => setQuestion(e.target.value)}
        />
        <Form.Item label="Type:" name="type" className="mt-2">
          <Select
            name="type"
            style={{ width: "95%" }}
            placeholder="type"
            onChange={(value) => {
              setType(value);
            }}
            options={[
              {
                value: "0",
                label: "Multiple Choice",
              },
              {
                value: "1",
                label: "Rating (5 Options)",
              },
              {
                value: "2",
                label: "Rating (10 Options)",
              },
              {
                value: "3",
                label: "Essay",
              },
            ]}
          />
        </Form.Item>
        {type === "0" ? (
          <div>
            <p>Option a:</p>
            <input
              type="text"
              name="option_a"
              className="w-95"
              placeholder="option a"
              defaultValue={dataQuestionSurvey.option?.option_a}
              onChange={handleChange}
            />
            <p>Option b:</p>
            <input
              type="text"
              name="option_b"
              className="w-95"
              placeholder="option b"
              defaultValue={dataQuestionSurvey.option?.option_b}
              onChange={handleChange}
            />
            <p>Option c:</p>
            <input
              name="option_c"
              className="w-95"
              placeholder="option c"
              defaultValue={dataQuestionSurvey.option?.option_c}
              onChange={handleChange}
            />
            <p>Option d:</p>
            <input
              type="text"
              name="option_d"
              className="w-95"
              placeholder="option d"
              defaultValue={dataQuestionSurvey.option?.option_d}
              onChange={handleChange}
            />
          </div>
        ) : type === "1" ? (
          <form>
            <p>Option 1:</p>
            <input
              type="text"
              name="option_1"
              className="w-95"
              placeholder="option 1"
              defaultValue={
                dataQuestionSurvey.type === "1"
                  ? dataQuestionSurvey.option?.option_1
                  : ""
              }
              onChange={handleChange1}
            />
            <p>Option 2:</p>
            <input
              type="text"
              name="option_2"
              className="w-95"
              placeholder="option 2"
              defaultValue={
                dataQuestionSurvey.type === "1"
                  ? dataQuestionSurvey.option?.option_2
                  : ""
              }
              onChange={handleChange1}
            />
            <p>Option 3:</p>
            <input
              name="option_3"
              className="w-95"
              placeholder="option 3"
              defaultValue={
                dataQuestionSurvey.type === "1"
                  ? dataQuestionSurvey.option?.option_3
                  : ""
              }
              onChange={handleChange1}
            />
            <p>Option 4:</p>
            <input
              type="text"
              name="option_4"
              className="w-95"
              placeholder="option 4"
              defaultValue={
                dataQuestionSurvey.type === "1"
                  ? dataQuestionSurvey.option?.option_4
                  : ""
              }
              onChange={handleChange1}
            />
            <p>Option 5:</p>
            <input
              type="text"
              name="option_5"
              className="w-95"
              placeholder="option 5"
              defaultValue={
                dataQuestionSurvey.type === "1"
                  ? dataQuestionSurvey.option?.option_5
                  : ""
              }
              onChange={handleChange1}
            />
          </form>
        ) : type === "2" ? (
          <Row>
            <Col span={12}>
              <p>Option 1:</p>
              <input
                type="text"
                name="option_1"
                className="w-95"
                placeholder="option 1"
                defaultValue={
                  dataQuestionSurvey.type === "2"
                    ? dataQuestionSurvey.option?.option_1
                    : ""
                }
                onChange={handleChange2}
              />
              <p>Option 2:</p>
              <input
                type="text"
                name="option_2"
                className="w-95"
                placeholder="option 2"
                defaultValue={
                  dataQuestionSurvey.type === "2"
                    ? dataQuestionSurvey.option?.option_2
                    : ""
                }
                onChange={handleChange2}
              />
              <p>Option 3:</p>
              <input
                name="option_3"
                className="w-95"
                placeholder="option 3"
                defaultValue={
                  dataQuestionSurvey.type === "2"
                    ? dataQuestionSurvey.option?.option_3
                    : ""
                }
                onChange={handleChange2}
              />
              <p>Option 4:</p>
              <input
                type="text"
                name="option_4"
                className="w-95"
                placeholder="option 4"
                defaultValue={
                  dataQuestionSurvey.type === "2"
                    ? dataQuestionSurvey.option?.option_4
                    : ""
                }
                onChange={handleChange2}
              />
              <p>Option 5:</p>
              <input
                type="text"
                name="option_5"
                className="w-95"
                placeholder="option 5"
                defaultValue={
                  dataQuestionSurvey.type === "2"
                    ? dataQuestionSurvey.option?.option_5
                    : ""
                }
                onChange={handleChange2}
              />
            </Col>
            <Col span={12}>
              <p>Option 6:</p>
              <input
                type="text"
                name="option_6"
                className="w-95"
                placeholder="option 6"
                defaultValue={
                  dataQuestionSurvey.type === "2"
                    ? dataQuestionSurvey.option?.option_6
                    : ""
                }
                onChange={handleChange2}
              />
              <p>Option 7:</p>
              <input
                type="text"
                name="option_7"
                className="w-95"
                placeholder="option 7"
                defaultValue={
                  dataQuestionSurvey.type === "2"
                    ? dataQuestionSurvey.option?.option_7
                    : ""
                }
                onChange={handleChange2}
              />
              <p>Option 8:</p>
              <input
                name="option_8"
                className="w-95"
                placeholder="option 8"
                defaultValue={
                  dataQuestionSurvey.type === "2"
                    ? dataQuestionSurvey.option?.option_8
                    : ""
                }
                onChange={handleChange2}
              />
              <p>Option 9:</p>
              <input
                type="text"
                name="option_9"
                className="w-95"
                placeholder="option 9"
                defaultValue={
                  dataQuestionSurvey.type === "2"
                    ? dataQuestionSurvey.option?.option_9
                    : ""
                }
                onChange={handleChange2}
              />
              <p>Option 10:</p>
              <input
                type="text"
                name="option_10"
                className="w-95"
                placeholder="option 10"
                defaultValue={
                  dataQuestionSurvey.type === "2"
                    ? dataQuestionSurvey.option?.option_10
                    : ""
                }
                onChange={handleChange2}
              />
            </Col>
          </Row>
        ) : (
          ""
        )}
      </Form>
      <Button type="primary btn-submit mt-2" onClick={handleUpdate}>
        Submit
      </Button>
    </div>
  );
};

export default UpdateQuestionSurvey;
