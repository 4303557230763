import { Button, Form, message } from "antd";
import Title from "antd/es/typography/Title";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Navigation from "../../components/layout/Navigation";

const Settings = () => {
  const [dataPoint, setDataPoint] = useState([""]);
  const [isLoading, setIsLoading] = useState(true);
  const token = localStorage.getItem("token");

  const [form, setForm] = useState({
    dailylogin: 0,
    posttweetvalues: 0,
    liketweetvalues: 0,
    blockedtweetvalues: 0,
    uploadforum: 0,
    reportedforum: 0,
    likeforum: 0,
    bestanswerforum: 0,
    commentingforum: 0,
    sharedforum: 0,
  });

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL}cultureapp/settings/point`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        setDataPoint(res.data.data);
      })
      .catch((err) => {
        alert(err);
      });
  }, []);

  const point = dataPoint.map((item) => item.point);

  const handleUpdate = () => {
    axios
      .put(
        `${process.env.REACT_APP_URL}cultureapp/settings/update_settings_point`,
        {
          data: [
            { id: 1, point: form.dailylogin ? form.dailylogin : point[0] },
            {
              id: 2,
              point: form.posttweetvalues ? form.posttweetvalues : point[1],
            },
            {
              id: 3,
              point: form.liketweetvalues ? form.liketweetvalues : point[2],
            },
            {
              id: 4,
              point: form.blockedtweetvalues
                ? form.blockedtweetvalues
                : point[3],
            },
            {
              id: 5,
              point: form.uploadforum ? form.uploadforum : point[4],
            },
            {
              id: 6,
              point: form.reportedforum ? form.reportedforum : point[5],
            },
            {
              id: 7,
              point: form.likeforum ? form.likeforum : point[6],
            },
            {
              id: 8,
              point: form.bestanswerforum ? form.bestanswerforum : point[7],
            },
            {
              id: 9,
              point: form.commentingforum ? form.commentingforum : point[8],
            },
            {
              id: 10,
              point: form.sharedforum ? form.sharedforum : point[9],
            },
          ],
        },
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        message.success("You have successfully updated!");
      })
      .catch((err) => {
        message.error("Error! Try again.");
      });
  };

  return (
    <>
      <Navigation title="Settings"/>
      <Title level={2}>Point</Title>
      <Form layout="vertical" className="row-col form-question">
        {dataPoint.map((item) => {
          return (
            <>
              <p>{item.title}</p>
              <input
                type="number"
                name={item.shortcode}
                className="w-95"
                placeholder={item.title}
                defaultValue={item.point}
                onChange={handleChange}
              />
            </>
          );
        })}
      </Form>
      <Button type="primary w-95 mt-2 btn-submit" onClick={handleUpdate}>
        Submit
      </Button>
    </>
  );
};

export default Settings;
