import { Button, Form, Input, message } from "antd";
import Title from "antd/es/typography/Title";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState } from "draft-js";
import { convertToHTML } from "draft-convert";
import Navigation from "../../components/layout/Navigation";

const AddNews = () => {
  const navigate = useNavigate();
  const [banner, setBanner] = useState(null);
  const token = localStorage.getItem("token");
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [convertedContent, setConvertedContent] = useState(null);

  useEffect(() => {
    const html = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(html);
  }, [editorState]);

  const [form, setForm] = useState({
    title: "",
  });

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handlePic = (e) => {
    e.preventDefault();
    setBanner(e.target.files[0]);
  };

  const handleSubmit = () => {
    const PPData = new FormData();
    PPData.append("banner", banner);
    PPData.append("title", form.title);
    PPData.append("description", convertedContent);
    axios
      .post(`${process.env.REACT_APP_URL}cultureapp/news/add`, PPData, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        navigate("/news");
        message.success("You have successfully submitted!");
      })
      .catch((err) => {
        message.error("Error! Try again.");
      });
  };

  const toolbarOptions = {
    options: ["inline", "fontSize", "blockType", "list"],
    inline: {
      options: ["bold", "italic", "underline", "strikethrough"],
    },
    list: {
      options: ["unordered", "ordered"],
    },
    blockType: {
      options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
    },
  };

  return (
    <>
      <Navigation
        link="/news"
        title="News"
        link2="/news/add-news"
        title2="Add News"
      />
      <Title>Add News</Title>
      <Form layout="vertical" className="row-col">
        <div>
          {banner ? (
            <img alt="" width={150} src={URL.createObjectURL(banner)} />
          ) : (
            ""
          )}
        </div>
        <Form.Item label="Set Image:" name="banner" className="mt-3">
          <Input
            type="file"
            name="banner"
            className="w-95"
            onChange={handlePic}
          />
        </Form.Item>
        <Form.Item label="Title:" name="title">
          <Input
            type="text"
            name="title"
            className="w-95"
            placeholder="title"
            value={form.title}
            onChange={handleChange}
          />
        </Form.Item>
        <Form.Item label="Description:" name="body">
          <Editor
            editorState={editorState}
            onEditorStateChange={setEditorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="w-95"
            editorClassName="editorClassName"
            toolbar={toolbarOptions}
          />
        </Form.Item>
      </Form>
      <Button type="primary w-95 btn-submit" onClick={handleSubmit}>
        Submit
      </Button>
    </>
  );
};

export default AddNews;
