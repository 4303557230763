import { Button, message, Pagination, Space, Table, Upload } from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import { ModalDelete } from "../../components/layout/ModalDelete";
import { InfoCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { AddButton } from "../../components/layout/AddButton";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Navigation from "../../components/layout/Navigation";
import Search from "antd/es/input/Search";

const Report = () => {
  const navigate = useNavigate();
  const [dataQuestion, setDataQuestion] = useState([""]);
  const [totalData, setTotalData] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [current, setCurrent] = useState(1);
  const token = localStorage.getItem("token");

  const getData = (pageSize, pageIndex, searchIndex) => {
    axios
      .get(
        `${process.env.REACT_APP_URL}cultureapp/questionbank/list/${
          pageSize ?? 10
        }/${pageIndex ?? 1}`,
        {
          headers: { Authorization: "Bearer " + token },
          params: { search: searchIndex },
        }
      )
      .then((res) => {
        setDataQuestion(res.data.data);
        setTotalData(res.data.totaldata);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleUploadExcel = ({ e, fileList }) => {
    const PPData = new FormData();
    PPData.append("excelFile", fileList[0].originFileObj);
    axios
      .post(
        `${process.env.REACT_APP_URL}cultureapp/questionbank/upload_file_excel`,
        PPData,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        window.location.reload(false);
        message.success("You have successfully submitted!");
      })
      .catch((err) => {
        message.error("Error! Try again.");
      });
    e.preventDefault();
  };

  const data = dataQuestion.map((row) => ({
    id: row.id,
    no: row.number,
    question: row.question,
  }));

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "5%",
    },
    {
      title: "Terlapor",
      children: [
        {
          title: "NPP",
          dataIndex: "category",
          key: "category",
        },
        {
          title: "Nama",
          dataIndex: "category",
          key: "category",
        },
        {
          title: "Unit Kerja",
          dataIndex: "category",
          key: "category",
        },
        {
          title: "Pertanyaan",
          dataIndex: "category",
          key: "category",
        },
        {
          title: "Tanggal",
          dataIndex: "category",
          key: "category",
        },
      ],
    },
    {
      title: "Pelapor",
      children: [
        {
          title: "NPP",
          dataIndex: "category",
          key: "category",
        },
        {
          title: "Nama",
          dataIndex: "category",
          key: "category",
        },
        {
          title: "Tanggal",
          dataIndex: "category",
          key: "category",
        },
      ],
    },
    {
      title: "Action",
      key: "action",
      width: "15%",
      render: (_, record) => (
        <Space size="middle">
          <Button
            className="outline-0"
            onClick={() => navigate(`/detail-categories/${record.id}`)}
          >
            <InfoCircleOutlined />
          </Button>
          <ModalDelete url="questionbank" id={record.id}></ModalDelete>
        </Space>
      ),
    },
  ];
  return (
    <>
      <Navigation title="Forum" title2="Report" />
      <Title level={2}>Report</Title>
      <div className="title">
        <Search
          placeholder="Search Name"
          onSearch={(value) => {
            getData(10, 1, value);
          }}
          style={{ width: "60%" }}
          allowClear
        />
      </div>
      <Title level={5} className="mt-3">
        Pertanyaan
      </Title>
      <Table
        className="mt-2"
        dataSource={data}
        columns={columns}
        loading={isLoading}
        pagination={false}
      />
      <Title level={5} className="mt-3">
        Jawaban
      </Title>
      <Table
        className="mt-2"
        dataSource={data}
        columns={columns}
        loading={isLoading}
        pagination={false}
      />
    </>
  );
};

export default Report;
