import Title from "antd/es/typography/Title";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Space } from "antd";
import DOMPurify from "dompurify";

const DetailNews = () => {
  const [news, setNews] = useState([""]);
  const { id } = useParams();
  const token = localStorage.getItem("token");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL}cultureapp/news/detail/${id}`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        setNews(res.data);
      })
      .catch((err) => {
        alert(err);
      });
  }, [token, id]);

  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  return (
    <div className="text-center">
      <Space direction="vertical" size="middle">
        <Title>{news.title}</Title>
        <div>
          <img alt="" width={350} src={news.banner} />
        </div>
        <div
          className="preview"
          dangerouslySetInnerHTML={createMarkup(news.body)}
        ></div>
      </Space>
    </div>
  );
};

export default DetailNews;
