import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Pagination,
  Select,
  Space,
  Table,
} from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Option } from "antd/es/mentions";
import Navigation from "../../components/layout/Navigation";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Search from "antd/es/input/Search";

const AddQuestionQuizDetail = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [dataQuestion, setDataQuestion] = useState([""]);
  const [dataQuestionDetail, setDataQuestionDetail] = useState([""]);
  const [selectedRowKeys, setSelectedRowKeys] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [totalData, setTotalData] = useState(0);
  const [current, setCurrent] = useState(1);
  const { id } = useParams();
  const token = localStorage.getItem("token");

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hideModal = () => {
    setOpen(false);
  };

  const selectedRowKeysString = selectedRowKeys.toString().replace(/,/g, ";");

  const addQuestion = () => {
    axios
      .post(
        `${process.env.REACT_APP_URL}cultureapp/quiz/set_question_quiz`,
        { id_quiz: id, list_id_question: selectedRowKeysString },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        navigate(`/quiz/question-quiz-detail/${id}`);
        message.success("You have succesfully submitted!");
      })
      .catch((err) => {
        message.error("Error! Try again.");
      });
  };

  const getData = (pageSize, pageIndex, searchIndex) => {
    axios
      .get(
        `${process.env.REACT_APP_URL}cultureapp/quiz/add_question_quiz/${id}/${
          pageSize ?? 10
        }/${pageIndex ?? 1}`,
        {
          headers: { Authorization: "Bearer " + token },
          params: { search: searchIndex },
        }
      )
      .then((res) => {
        setDataQuestion(res.data.data);
        setTotalData(res.data.totaldata);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const fetchDataQuestion = (id) => {
    axios
      .get(`${process.env.REACT_APP_URL}cultureapp/questionbank/detail/${id}`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        setDataQuestionDetail(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const data = dataQuestion.map((row) => ({
    no: row.number,
    key: row.id,
    question: row.question,
  }));

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "5%",
    },
    {
      title: "Question",
      dataIndex: "question",
      key: "question",
    },
    {
      title: "Action",
      key: "action",
      width: "15%",
      render: (_, record) => (
        <Space size="middle">
          <Button
            className="outline-0"
            onClick={() => {
              setOpen(true);
              fetchDataQuestion(record.key);
            }}
          >
            <InfoCircleOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Navigation
        link="/quiz"
        title="Quiz"
        link2={`/quiz/question-quiz-detail/${id}`}
        title2="Question Quiz Detail"
        title3="Add Questions in Quiz"
      />
      <Title level={2}>Add Questions in Quiz</Title>
      <div className="title">
        <Search
          placeholder="Search Name"
          onSearch={(value) => {
            getData(10, 1, value);
          }}
          style={{ width: "60%" }}
          allowClear
        />
        <Button type="primary btn-submit" onClick={addQuestion}>
          Submit
        </Button>
      </div>
      <Table
        className="mt-2"
        rowSelection={rowSelection}
        dataSource={data}
        columns={columns}
        loading={isLoading}
        pagination={false}
      />
      <Pagination
        className="mt-3"
        current={current}
        total={totalData}
        onChange={(page, size) => {
          getData(size, page);
          setCurrent(page);
        }}
      />

      {/* for modal */}
      <Modal
        title="Question Detail"
        open={open}
        onCancel={hideModal}
        footer={null}
      >
        <Form
          layout="vertical"
          className="row-col form-question"
          fields={[
            {
              name: ["title"],
              value: dataQuestionDetail.question,
            },
            {
              name: ["option_a"],
              value: dataQuestionDetail.option_a,
            },
            {
              name: ["option_b"],
              value: dataQuestionDetail.option_b,
            },
            {
              name: ["option_c"],
              value: dataQuestionDetail.option_c,
            },
            {
              name: ["option_d"],
              value: dataQuestionDetail.option_d,
            },
            {
              name: ["correctanswer"],
              value: dataQuestionDetail.correctanswer,
            },
          ]}
          disabled={true}
        >
          <Form.Item label="Title:" name="title">
            <Input type="text" name="title" className="w-95" />
          </Form.Item>
          <Form.Item label="A:" name="option_a">
            <Input type="text" name="option_a" className="w-95" />
          </Form.Item>
          <Form.Item label="B:" name="option_b">
            <Input type="text" name="option_b" className="w-95" />
          </Form.Item>
          <Form.Item label="C:" name="option_c">
            <Input type="text" name="option_c" className="w-95" />
          </Form.Item>
          <Form.Item label="D:" name="option_d">
            <Input type="text" name="option_d" className="w-95" />
          </Form.Item>
          <Form.Item label="Answer:" name="correctanswer">
            <Select
              name="name"
              style={{ width: "95%" }}
              options={[
                {
                  value: "a",
                  label: "a",
                },
                {
                  value: "b",
                  label: "b",
                },
                {
                  value: "c",
                  label: "c",
                },
                {
                  value: "d",
                  label: "d",
                },
              ]}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddQuestionQuizDetail;
