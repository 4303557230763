import React from "react";
import "antd/dist/reset.css";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import CoreValues from "./pages/CoreValues";
import Quiz from "./pages/Quiz";
import MainLayout from "./components/layout/MainLayout";
import AddValue from "./pages/AddValue";
import AddQuiz from "./pages/AddQuiz"
import Notification from "./pages/Notification"
import AddNotification from "./pages/AddNotification"
import UserManagement from "./pages/UserManagement"
import AddUser from "./pages/AddUser";
import News from "./pages/News";
import AddNews from "./pages/AddNews"
import TweetValues from "./pages/TweetValues";
import UpdateValue from "./pages/UpdateValue"
import UpdateQuiz from "./pages/UpdateQuiz"
import RequireAuth from "./components/base/RequireAuth";
import Dashboard from "./pages/Dashboard";
import QuestionQuizDetail from "./pages/QuestionQuizDetail";
import UpdateNotification from "./pages/UpdateNotification"
import UpdateUser from "./pages/UpdateUser"
import UpdateNews from "./pages/UpdateNews";
import DetailNews from "./pages/DetailNews";
import CaseStudy from "./pages/CaseStudy";
import Settings from "./pages/Settings";
import UploadContent from "./pages/UploadContent";
import Survey from "./pages/Survey";
import AddSurvey from "./pages/AddSurvey";
import QuestionBankQuiz from "./pages/QuestionBankQuiz";
import QuestionBankSurvey from "./pages/QuestionBankSurvey";
import Categories from "./pages/Categories";
import DetailCategories from "./pages/DetailCategories";
import AddForum from "./pages/AddForum";
import Report from "./pages/Report";
import UpdateSurvey from "./pages/UpdateSurvey";
import UpdateQuestionSurvey from "./pages/UpdateQuestionSurvey";
import QuestionSurveyDetail from "./pages/QuestionSurveyDetail";
import AddQuestionSurveyDetail from "./pages/AddQuestionSurveyDetail";
import AddQuestionBankQuiz from "./pages/AddQuestionBankQuiz";
import AddQuestionBankSurvey from "./pages/AddQuestionBankSurvey";
import AddQuestionQuizDetail from "./pages/AddQuestionQuizDetail";
import UpdateQuestionQuiz from "./pages/UpdateQuestionQuiz";
import DetailForum from "./pages/DetailForum";
import DetailSurvey from "./pages/DetailSurvey";
import DetailQuiz from "./pages/DetailQuiz";

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route element={
        <RequireAuth>
          <MainLayout />
        </RequireAuth>
      }>
        <Route path="/" element={<Dashboard />} />
        <Route path="/core-values" element={<CoreValues />} />
        <Route path="/core-values/add-value" element={<AddValue />} />
        <Route path="/core-values/update-value/:id" element={<UpdateValue />} />
        <Route path="/core-values/case-study/:id" element={<CaseStudy />} />
        <Route path="/quiz/question-bank-quiz" element={<QuestionBankQuiz />} />
        <Route path="/quiz/add-question-bank-quiz" element={<AddQuestionBankQuiz />} />
        <Route path="/quiz/update-question-quiz/:id" element={<UpdateQuestionQuiz />} />
        <Route path="/quiz" element={<Quiz />} />
        <Route path="/quiz/add-quiz" element={<AddQuiz />} />
        <Route path="/quiz/update-quiz/:id" element={<UpdateQuiz />} />
        <Route path="/quiz/detail-quiz/:id" element={<DetailQuiz />} />
        <Route path="/quiz/question-quiz-detail/:id" element={<QuestionQuizDetail />} />
        <Route path="/quiz/add-question-quiz-detail/:id" element={<AddQuestionQuizDetail />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/notification/add-notification" element={<AddNotification />} />
        <Route path="/notification/update-notification/:id" element={<UpdateNotification />} />
        <Route path="/tweet-values" element={<TweetValues />} />
        <Route path="/user-management" element={<UserManagement />} />
        <Route path="/user-management/add-user" element={<AddUser />} />
        <Route path="/user-management/update-user/:id" element={<UpdateUser />} />
        <Route path="/news" element={<News />} />
        <Route path="/news/add-news" element={<AddNews />} />
        <Route path="/news/update-news/:id" element={<UpdateNews />} />
        <Route path="/news/detail-news/:id" element={<DetailNews />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/upload-content" element={<UploadContent />} />
        <Route path="/survey" element={<Survey />} />
        <Route path="/survey/add-survey" element={<AddSurvey />} />
        <Route path="/survey/update-survey/:id" element={<UpdateSurvey />} />
        <Route path="/survey/detail-survey/:id" element={<DetailSurvey />} />
        <Route path="/survey/question-bank-survey" element={<QuestionBankSurvey />} />
        <Route path="/survey/add-question-bank-survey" element={<AddQuestionBankSurvey />} />
        <Route path="/survey/update-question-survey/:id" element={<UpdateQuestionSurvey />} />
        <Route path="/survey/question-survey-detail/:id" element={<QuestionSurveyDetail />} />
        <Route path="/survey/add-question-survey-detail/:id" element={<AddQuestionSurveyDetail />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/detail-categories/:id" element={<DetailCategories />} />
        <Route path="/add-forum/:id" element={<AddForum />} />
        <Route path="/detail-forum/:id" element={<DetailForum />} />
        <Route path="/report" element={<Report />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

export default App;