import { Button, Form, message, Space } from "antd";
import Title from "antd/es/typography/Title";
import Text from "antd/es/typography/Text";
import { DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import Navigation from "../../components/layout/Navigation";

const UpdateQuiz = () => {
  const { RangePicker } = DatePicker;
  const navigate = useNavigate();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const { id } = useParams();
  const [dataQuiz, setDataQuiz] = useState("");
  const token = localStorage.getItem("token");
  const [form, setForm] = useState({
    title: "",
    duration: 0,
  });

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL}cultureapp/quiz/detail/${id}`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        setDataQuiz(res.data);
      })
      .catch((err) => {
        alert(err);
      });
  }, [id, token]);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const onChange = (dateString) => {
    setStartTime(dateString[0]);
    setEndTime(dateString[1]);
  };

  const convertedTimeStart = Math.floor(
    new Date(dataQuiz.timestart).getTime() / 1000
  );
  const convertedTimeEnd = Math.floor(
    new Date(dataQuiz.timeend).getTime() / 1000
  );
  const convertedStartTime = Math.floor(new Date(startTime).getTime() / 1000);
  const convertedEndTime = Math.floor(new Date(endTime).getTime() / 1000);

  const handleAdd = () => {
    axios
      .put(
        `${process.env.REACT_APP_URL}cultureapp/quiz/update/${id}`,
        {
          title: form.title ? form.title : dataQuiz?.title,
          duration: form.duration ? form.duration : dataQuiz?.duration,
          timestart: convertedStartTime
            ? convertedStartTime
            : convertedTimeStart,
          timeend: convertedEndTime ? convertedEndTime : convertedTimeEnd,
        },
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        navigate("/quiz");
        message.success("You have successfully updated!");
      })
      .catch((err) => {
        message.error("Error! Try again.");
      });
  };

  return (
    <div className="">
      <Navigation link="/quiz" title="Quiz" title2="Update Quiz " />
      <Title>Update Quiz</Title>
      <Form
        layout="vertical"
        className="row-col form-question"
        fields={[
          {
            name: ["date"],
            value: [
              dayjs(startTime ? startTime : dataQuiz.timestart),
              dayjs(endTime ? endTime : dataQuiz.timeend),
            ],
          },
        ]}
      >
        <p>Title:</p>
        <input
          type="text"
          name="title"
          className="w-95"
          placeholder="title"
          defaultValue={dataQuiz.title}
          onChange={handleChange}
        />
        <Form.Item label="Date:" name="date" style={{ marginTop: "1rem" }}>
          <RangePicker
            showTime={{
              format: "HH:mm",
            }}
            format="YYYY-MM-DD HH:mm"
            onChange={onChange}
          />
        </Form.Item>
        <p>Duration:</p>
        <input
          type="number"
          name="duration"
          className="w-95"
          placeholder="duration"
          defaultValue={dataQuiz.duration}
          onChange={handleChange}
        />
      </Form>
      <Button type="primary w-95 mt-2 btn-submit" onClick={handleAdd}>
        Submit
      </Button>
    </div>
  );
};

export default UpdateQuiz;
