import { Button, Col, Form, Input, Layout, message, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { Content } from "antd/es/layout/layout";
import Title from "antd/es/typography/Title";
import React, { useState } from "react";
import axios from "axios";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import img from "../../assets/_edit_bumn_02.jpg"
import logo from "../../assets/logo-bumn-alternatif.jpg"

const Login = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    username: "",
    password: "",
  });

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleLogin = () => {
    axios
      .post(`${process.env.REACT_APP_URL}cultureapp/auth/login`, form, {
        headers: {
          Authorization:
            "Basic " +
            btoa(
              `${process.env.REACT_APP_USERNAME}:${process.env.REACT_APP_PASSWORD}`
            ),
        },
      })
      .then((res) => {
        localStorage.setItem("token", res.data.user.token.access_token);
        localStorage.setItem("auth", 1);
        message.success("You have successfully logged in!");
        navigate("/");
      })
      .catch((err) => {
        message.error("Wrong email or password, try again!");
      });
  };

  return (
    <>
      <Layout className="layout-signin bg-white">
        <Content className="">
          <Row
            gutter={[24, 0]}
            justify="space-around"
            style={{ marginRight: "-18px" }}
          >
            <Col
              className="signin"
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 8, offset: 2 }}
              md={{ span: 12 }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                {/* <img
                  width={190}
                  height={80}
                  src={logo}
                  alt=""
                /> */}
                <Title level={1} className="fw-bold mt-3">
                  Sign In
                </Title>
              </div>
              <Title
                className="text-secondary"
                level={5}
                style={{ color: "#8c8c8c" }}
              >
                Enter your username and password to sign in
              </Title>
              <Form
                layout="vertical"
                className="row-col"
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleLogin();
                  }
                }}
              >
                <Form.Item
                  className="username"
                  label="Username"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please input your username!",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    name="username"
                    className="form-control p-3"
                    placeholder="Enter your username"
                    value={form.username}
                    onChange={handleChange}
                  />
                </Form.Item>
                <Form.Item
                  className="username"
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password
                    type="password"
                    name="password"
                    className="form-control p-3"
                    placeholder="Enter your password"
                    value={form.password}
                    onChange={handleChange}
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    style={{ width: "100%" }}
                    onClick={handleLogin}
                  >
                    Sign In
                  </Button>
                </Form.Item>
              </Form>
            </Col>
            <Col
              className="layout-img"
              style={{ padding: 12 }}
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
            >
              <img
                className="img-login"
                src={img}
                alt=""
              />
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
};

export default Login;
