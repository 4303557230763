import { Button, Form, Input, message, Select } from "antd";
import Title from "antd/es/typography/Title";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navigation from "../../components/layout/Navigation";

const UpdateQuestionQuiz = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [answer, setAnswer] = useState("");
  const [dataQuestion, setDataQuestion] = useState("");
  const token = localStorage.getItem("token");

  const [form, setForm] = useState({
    question: "",
    option_a: "",
    option_b: "",
    option_c: "",
    option_d: "",
  });

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL}cultureapp/questionbank/detail/${id}`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        setDataQuestion(res.data);
      })
      .catch((err) => {
        alert(err);
      });
  }, [id, token]);

  const handleUpdate = () => {
    const token = localStorage.getItem("token");
    axios
      .put(
        `${process.env.REACT_APP_URL}cultureapp/questionbank/update/${id}`,
        {
          question: form.question ? form.question : dataQuestion?.question,
          option_a: form.option_a ? form.option_a : dataQuestion?.option_a,
          option_b: form.option_b ? form.option_b : dataQuestion?.option_b,
          option_c: form.option_c ? form.option_c : dataQuestion?.option_c,
          option_d: form.option_d ? form.option_d : dataQuestion?.option_d,
          correctanswer: answer ? answer : dataQuestion?.correctanswer,
        },
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        navigate("/quiz/question-bank-quiz");
        message.success("You have successfully updated!");
      })
      .catch((err) => {
        message.error("Error! Try again.");
      });
  };

  return (
    <div className="form-question">
      <Navigation
        link="/quiz"
        title="Quiz"
        link2="/quiz/question-bank-quiz"
        title2="Question Bank Quiz"
        title3="Update Question Bank Quiz"
      />
      <Title>Update Question Bank Quiz</Title>
      <Form
        layout="vertical"
        className="row-col form-question"
        fields={[
          {
            name: ["correctanswer"],
            value: answer ? answer : dataQuestion.correctanswer,
          },
        ]}
      >
        <p>Question:</p>
        <input
          type="text"
          name="question"
          className="w-95"
          placeholder="question"
          defaultValue={dataQuestion.question}
          onChange={handleChange}
        />

        <p>Option a:</p>
        <input
          type="text"
          name="option_a"
          className="w-95"
          placeholder="option a"
          defaultValue={dataQuestion.option_a}
          onChange={handleChange}
        />
        <p>Option b:</p>
        <input
          type="text"
          name="option_b"
          className="w-95"
          placeholder="option b"
          defaultValue={dataQuestion.option_b}
          onChange={handleChange}
        />
        <p>Option c:</p>
        <input
          type="text"
          name="option_c"
          className="w-95"
          placeholder="option c"
          defaultValue={dataQuestion.option_c}
          onChange={handleChange}
        />
        <p>Option d:</p>
        <input
          type="text"
          name="option_d"
          className="w-95"
          placeholder="option d"
          defaultValue={dataQuestion.option_d}
          onChange={handleChange}
        />
        <Form.Item
          label="Correct answer:"
          name="correctanswer"
          style={{ marginTop: "1rem" }}
        >
          <Select
            name="correctanswer"
            style={{ width: "95%" }}
            placeholder="correct answer"
            onChange={(value) => setAnswer(value)}
            options={[
              {
                value: "a",
                label: "a",
              },
              {
                value: "b",
                label: "b",
              },
              {
                value: "c",
                label: "c",
              },
              {
                value: "d",
                label: "d",
              },
            ]}
          />
        </Form.Item>
      </Form>
      <Button type="primary btn-submit" onClick={handleUpdate}>
        Submit
      </Button>
    </div>
  );
};

export default UpdateQuestionQuiz;
